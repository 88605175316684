// @ts-check

import { defineStore } from "pinia";
import { useUserStore } from "@/stores/users.js";
import { usePostStore } from "@/stores/post.js";
import { useAuthStore } from "@/stores/auth.js";
import { apiBackendAuthAxios } from "@/axiosAuth.js";

export const useGlobalStore = defineStore("global", {
  state: () => ({
    // Store properties
    draftPosts: {},
    reportedPosts: [],
    processingPosts: [],
    playingVideoId: null,
    badgeTypes: [],
    reactionTypes: [],
    notificationTypes: [],
    following: [],
    lastUpdated: null,
    isLoadingBadgeTypes: false,
    isLoadingReactionTypes: false,
    lastClearedTime: null,
    clearDataInterval: null,
    defaultPrimaryColor: "#e50038",
    defaultSecondaryColor: "#942360",
    defaultTertiaryColor: "#e50038",
    currentFeed: "following",
    currentTenant: null,
    version: null,
    versionDate: null,
    isBackendDown: false,
    isUpdatingAccessToken: false,
    isParler: import.meta.env.VITE_IS_PARLER === "true" ? true : false,
    userViolations: [],
    violationCursor: null,
    violationCount: 0,
    redirect: null,
    isMuted: false,
    volume: 1,

    settings: {
      primary_colors: null,
      secondary_colors: null,
      tertiary_colors: null,
      auto_play: true,
      dark_mode: false,
      comment_sort: "popular",
      default_feed: "following",
      notifications_follow: true,
      notifications_follow_request: true,
      notifications_follow_accepted: true,
      notifications_comment: true,
      notifications_reaction: true,
      notifications_mention: true,
      notifications_repost: true,
      notifications_repost_with_comment: true,
      notifications_push_notifications: true,
    },
  }),
  actions: {
    updateMuteState(isMuted) {
      this.isMuted = isMuted;
    },

    updateVolume(volume) {
      this.volume = volume;
    },

    async refreshTypes() {
      await this.fetchBadgeTypes();
      await this.fetchReactionTypes();
      await this.getNotificationTypes();
    },

    setBackendDown(value) {
      console.log("Setting backend down:", value);
      this.isBackendDown = value;
      console.log("isBackendDown:", this.isBackendDown);
    },

    setUpdatingAccessToken(value) {
      console.log("Setting updating access token:", value);
      this.isUpdatingAccessToken = value;
      console.log("isUpdatingAccessToken:", this.isUpdatingAccessToken);
    },

    async fetchUserViolations() {
      try {
        const response = await apiBackendAuthAxios.get("/user/violations", {
          params: {
            cursor: this.violationCursor,
          },
        });
        this.userViolations = response.data.data.map((violation) => ({
          name: violation.name,
          description: violation.description,
          points: violation.points,
          postId: violation.postId,
        }));

        // remove duplicates by postId
        this.userViolations = this.userViolations.filter(
          (v, i, a) => a.findIndex((t) => t.postId === v.postId) === i
        );

        this.violationCursor = response.data.next_cursor;
        return this.userViolations;
      } catch (error) {
        console.error("Error fetching user violations:", error);
        return [];
      }
    },

    async fetchViolationsByPostIds(postIds) {
      try {
        const response = await apiBackendAuthAxios.post(
          "/posts/mapViolations",
          {
            ulids: postIds,
          }
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching violations by post ids:", error);
        return [];
      }
    },

    async fetchSettings(initial = false) {
      try {
        const response = await apiBackendAuthAxios.get("/user/settings");
        const settingsData = response.data; // assuming this is the correct path to the settings array in the response

        // Update settings based on the response
        settingsData.forEach((setting) => {
          if (setting.name === "auto_play") {
            this.settings.auto_play = setting.value === "true";
          } else if (
            setting.name === "dark_mode" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.dark_mode = setting.value === "true";
          } else if (
            setting.name === "primary_colors" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.primary_colors = setting.value;
          } else if (
            setting.name === "secondary_colors" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.secondary_colors = setting.value;
          } else if (
            setting.name === "comment_sort" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.comment_sort = setting.value;
          } else if (
            setting.name === "notifications_follow" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_follow = setting.value === "true";
          } else if (
            setting.name === "notifications_follow_request" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_follow_request =
              setting.value === "true";
          } else if (
            setting.name === "notifications_push_notifications" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_push_notifications =
              setting.value === "true";
          } else if (
            setting.name === "notifications_follow_accepted" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_follow_accepted =
              setting.value === "true";
          } else if (
            setting.name === "notifications_reaction" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_reaction = setting.value === "true";
          } else if (
            setting.name === "notifications_mention" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_mention = setting.value === "true";
          } else if (
            setting.name === "notifications_comment" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_comment = setting.value === "true";
          } else if (
            setting.name === "notifications_repost" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_repost = setting.value === "true";
          } else if (
            setting.name === "notifications_repost_with_comment" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.notifications_repost_with_comment =
              setting.value === "true";
          } else if (
            setting.name === "default_feed" &&
            setting.value !== null &&
            setting.value !== "null"
          ) {
            this.settings.default_feed = setting.value;
          }
        });

        if (this.settings.dark_mode) {
          document.body.classList.add("dark");
        }

        if (
          this.settings.primary_colors !== null ||
          this.settings.secondary_colors !== null
        ) {
          this.settings.tertiary_colors = this.mixColors(
            this.settings.primary_colors.slice(1) ??
              this.defaultPrimaryColor.slice(1),
            this.settings.secondary_colors.slice(1) ??
              this.defaultSecondaryColor.slice(1)
          );

          if (this.settings.primary_colors !== null) {
            document.documentElement.style.setProperty(
              "--primary-color",
              this.settings.primary_colors
            );
          }

          if (this.settings.secondary_colors !== null) {
            document.documentElement.style.setProperty(
              "--secondary-color",
              this.settings.secondary_colors
            );
          }

          if (this.settings.tertiary_colors !== null) {
            document.documentElement.style.setProperty(
              "--tertiary-color",
              this.settings.tertiary_colors
            );
          }
        }

        if (!initial) {
          const authStore = useAuthStore();
          authStore.refreshUser();
        }
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    },

    mixColors(color1, color2) {
      // Convert hex to decimal
      function hexToDecimal(hex) {
        return parseInt(hex, 16);
      }

      // Convert decimal to hex
      function decimalToHex(decimal) {
        var hex = decimal.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }

      // Split the colors into their components
      var r1 = hexToDecimal(color1.slice(0, 2));
      var g1 = hexToDecimal(color1.slice(2, 4));
      var b1 = hexToDecimal(color1.slice(4, 6));

      var r2 = hexToDecimal(color2.slice(0, 2));
      var g2 = hexToDecimal(color2.slice(2, 4));
      var b2 = hexToDecimal(color2.slice(4, 6));

      // Average each component
      var rAvg = decimalToHex(Math.floor((r1 + r2) / 2));
      var gAvg = decimalToHex(Math.floor((g1 + g2) / 2));
      var bAvg = decimalToHex(Math.floor((b1 + b2) / 2));

      // Concatenate the average components back into a string
      return "#" + rAvg + gAvg + bAvg;
    },

    async updateSettings(name, value) {
      const payload = {
        [name]: value,
      };

      try {
        await apiBackendAuthAxios.patch("/user/settings", payload);

        this.settings[name] = value;

        if (name === "dark_mode") {
          this.setDarkMode();
        }

        const authStore = useAuthStore();
        authStore.refreshUser();
      } catch (error) {
        console.error("Failed to update settings:", error);
      }
    },

    setDarkMode() {
      this.settings.dark_mode
        ? document.body.classList.add("dark")
        : document.body.classList.remove("dark");

      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );

      if (themeColorMetaTag) {
        // Set the theme color based on dark mode setting
        themeColorMetaTag.setAttribute(
          "content",
          this.settings.dark_mode ? "#000000" : "#FFFFFF"
        );
      }
    },

    setupClearDataInterval() {
      const intervalDuration = 1000 * 60 * 60; // 1 hour

      // Clear any existing interval
      if (this.clearDataInterval) {
        clearInterval(this.clearDataInterval);
      }

      // Check if lastClearedTime is longer than an hour
      const currentTime = new Date().getTime();
      const lastClearedTime = this.lastClearedTime
        ? new Date(this.lastClearedTime).getTime()
        : 0;
      const timeDifference = currentTime - lastClearedTime;
      const shouldClearData = timeDifference > intervalDuration;
    },

    clearStaleData() {
      const postStore = usePostStore();
      const userStore = useUserStore();
      this.lastClearedTime = new Date().toISOString();
      postStore.clearStaleData();
      userStore.clearStaleData();
    },

    // Fetches all user settings
    async fetchBadgeTypes() {
      // If the badge types were fetched less than 24 hours ago, don't fetch them again
      if (
        this.isLoadingBadgeTypes ||
        (this.badgeTypes.length > 0 &&
          this.lastUpdated &&
          this.lastUpdated > Date.now() - 1000 * 60 * 60 * 24)
      ) {
        return this.badgeTypes;
      }

      try {
        this.isLoadingBadgeTypes = true;

        const response = await apiBackendAuthAxios.get("/settings/badges");

        this.badgeTypes = response.data.data; // Assuming the response body directly contains the badge types

        this.lastUpdated = Date.now();

        this.isLoadingBadgeTypes = false;
      } catch (error) {
        console.error("Failed to fetch badge types:", error);
      }
    },

    async fetchReactionTypes() {
      // If the reation types were fetched less than 24 hours ago, don't fetch them again
      if (
        this.isLoadingReactionTypes ||
        (this.reactionTypes.length > 0 &&
          this.lastUpdated &&
          this.lastUpdated > Date.now() - 1000 * 60 * 60 * 24)
      ) {
        return this.reactionTypes;
      }

      try {
        this.isLoadingReactionTypes = true;

        const response = await apiBackendAuthAxios.get("/settings/reactions");

        this.reactionTypes = response.data.data; // Assuming the response body directly contains the badge types

        this.lastUpdated = Date.now();

        this.isLoadingReactionTypes = false;
      } catch (error) {
        console.error("Failed to fetch reaction types:", error);
      }
    },

    // Fetches all notification types
    async getNotificationTypes() {
      try {
        const response = await apiBackendAuthAxios.get(
          "/user/notifications/types"
        );
        const notificationTypesData = response.data.data;
        console.log("notificationTypesData", notificationTypesData);
        this.notificationTypes = notificationTypesData;
        return notificationTypesData;
      } catch (error) {
        console.error(error);
      }
    },

    deleteDraft(draftKey) {
      try {
        delete this.draftPosts[draftKey];
      } catch (error) {
        console.error("Error in deleting Draft:", error);
      }
    },

    notify(
      title = "",
      body = "Parler Notification",
      data = "rtl",
      icon = "/src/assets/bellpar.png?c" ||
        "https://m.cdnparler.com/dev/01hpf6qqhqnpgpdehsqtqre8wx/sd/y6/01hqxje9nc57s135hpaza3sdy6.jpeg?width=256",
      image = "https://m.cdnparler.com/dev/01hpf6qqhqnpgpdehsqtqre8wx/sd/y6/01hqxje9nc57s135hpaza3sdy6.jpeg?width=256",
      tag = "",
      route = ""
    ) {
      const notification = new Notification(title, {
        body,
        data,
        icon,
        badge: image,
        tag,
      });

      if (route)
        notification.onclick = (e) => {
          e.preventDefault();
          window.open(route, "_blank");
        };
    },

    async enablePushNotifications() {
      if (!("serviceWorker" in navigator)) {
        console.error(
          "[Notifications] Service workers are not supported by this browser."
        );
        return;
      }

      if (!("Notification" in window)) {
        console.error("[Notifications] No support for notification API");
      }

      try {
        let registration = await navigator.serviceWorker.getRegistration();

        console.log("______registration", registration);

        if (!registration) {
          registration = await navigator.serviceWorker.register("/sw.js");
          // Wait for the service worker to be ready
          await navigator.serviceWorker.ready;
        }

        registration.addEventListener("updatefound", (e) => {
          // A wild service worker has appeared in reg.installing!
          const worker = registration.installing;

          worker.state;
          // "installing" - the install event has fired, but not yet complete
          // "installed"  - install complete
          // "activating" - the activate event has fired, but not yet complete
          // "activated"  - fully active
          // "redundant"  - discarded. Either failed install, or it's been
          //                replaced by a newer version

          worker.addEventListener("statechange", (e) => {
            console.log(
              "______newWorker.state in `updatefound` event",
              worker,
              e
            );
          });
        });

        const permission = await Notification.requestPermission();

        if (permission !== "granted") {
          console.error("Permission for Notifications was denied");
          return;
        }

        console.log("Permission granted:", registration);
        console.log("Permission granted:", permission);

        const subscription = await this.subscribeUserToPush(registration);
        if (subscription) {
          // Update subscription on your server
          this.updateSubscriptionOnServer(subscription);
          console.log("______subscription:", subscription);
        }

        this.updateSettings("notifications_push_notifications", true);

        // this.notify(
        //   "Parler",
        //   "Push notifications are enabled.",
        //   "",
        //   "",
        //   "",
        //   "",
        //   "https://app.parler.com/parler"
        // );
      } catch (error) {
        console.error(
          "Error during service worker registration or notification subscription:",
          error
        );

        // this.notify(
        //   "Parler",
        //   "Could not enable push notifications.",
        //   "",
        //   "",
        //   "",
        //   "",
        //   "https://app.parler.com/parler"
        // );
      }
    },

    async updateSubscriptionOnServer(subscription) {
      try {
        const key = subscription.getKey("p256dh");
        const token = subscription.getKey("auth");
        const contentEncoding = (PushManager.supportedContentEncodings || [
          "aesgcm",
        ])[0];

        console.log("______updateSubscriptionOnServer", subscription, {
          try: [key, token, contentEncoding],
        });

        // Convert the subscription object to a format suitable for your server

        const subscriptionData = {
          endpoint: subscription.endpoint,
          publicKey: key
            ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
            : null,
          authToken: token
            ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
            : null,
          contentEncoding: contentEncoding,
        };

        // Send the subscription data to your server using a POST request
        const response = await apiBackendAuthAxios.post(
          "/subscriptions",
          subscriptionData
        );

        console.log("______response", response);

        console.log("Subscription data sent to server:", response);
      } catch (error) {
        console.error("Failed to update subscription on server:", error);
      }
    },

    async unsubscribeUserFromPush() {
      if (!("serviceWorker" in navigator)) {
        console.error("Service workers are not supported by this browser.");
        return;
      }

      try {
        // Check if the service worker is already registered.
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          console.log("No service worker registered.");
          return;
        }

        // Get the current push subscription
        const subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
          console.log("No push subscription to unsubscribe.");
          return;
        }

        // Unsubscribe
        await subscription.unsubscribe();
        this.updateSettings("notifications_push_notifications", false);
        // Remove subscription on your server
        this.removeSubscriptionOnServer(subscription);
        console.log("Unsubscribed from push notifications.");
      } catch (error) {
        console.error("Error during push unsubscription:", error);
      }
    },

    async removeSubscriptionOnServer(subscription) {
      try {
        const endpoint = subscription.endpoint;
        await apiBackendAuthAxios.delete(
          `/subscriptions?endpoint=${encodeURIComponent(endpoint)}`
        );
      } catch (error) {
        console.error("Failed to remove subscription from server:", error);
      }
    },

    arrayBufferToBase64(buffer) {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    async subscribeUserToPush(registration) {
      const convertedVapidKey = this.urlBase64ToUint8Array(
        "BAg_nZMyJ58ljS-jjdDHol7miDlsJdnuBKAdFOlUKyZaVNGPdAw-ynBmiS_yPOA2HP9Cv2x4b2dWyE25zA6DZUo"
      );

      const options = {
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      };

      return registration.pushManager.subscribe(options);
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    async isPushEnabled() {
      if (
        !("serviceWorker" in navigator) ||
        this.settings.notifications_push_notifications === false
      ) {
        return false;
      }

      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        return subscription !== null;
      } catch (error) {
        console.error("Error checking push enabled status:", error);
        return false;
      }
    },

    async iosSubscribeToPush(token) {
      console.log(token, "iOS subscribing global");
      if (!token) {
        return;
      }
      const endpoint = `https://fcm.googleapis.com/fcm/send/${token}`;

      try {
        const subscription = {
          endpoint: endpoint,
          contentEncoding: "aes128gcm",
        };

        if (subscription) {
          const response = await apiBackendAuthAxios.post(
            "/subscriptions",
            subscription
          );
          console.log("______response", response);
          console.log("Subscription data sent to server:", response);

          return response;
        }
      } catch (error) {
        console.error("Failed to update subscription on server:", error);
      }
    },

    async iosUnsubscribeFromPush(token) {
      if (!token) {
        return;
      }

      try {
        const endpoint = `https://fcm.googleapis.com/fcm/send/${token}`;
        await apiBackendAuthAxios.delete(
          `/subscriptions?endpoint=${encodeURIComponent(endpoint)}`
        );
      } catch (error) {
        console.error("Failed to remove subscription from server:", error);
      }
    },
  },

  persist: true,
});
