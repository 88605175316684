<template :key="`menu-${id}`">
  <ion-icon
    :icon="ellipsisHorizontalOutline"
    :id="['popover-up-menu' + id]"
    @click.prevent="openPostMenu($event, userId)"
    :disabled="popoverOpen === true"
    class="p-1 text-xl cursor-pointer select-none"
  />
  <ion-popover
    :is-open="popoverOpen"
    :event="deletePostEvent"
    @didDismiss="popoverOpen = false"
    side="right"
    alignment="start"
  >
    <ion-list class="text-xs cursor-pointer select-none" nested lines="none">
      <ion-item
        v-if="
          globalStore.isParler &&
          isCurrentUserEmployee &&
          (!onDiscoverFeed || repostUsername === 'parlerdiscover')
        "
        :class="onDiscoverFeed ? 'text-red-500' : ''"
        @click="toggleDiscoverAlert"
      >
        <ion-icon slot="start" :icon="compassOutline"></ion-icon>
        <p>{{ onDiscoverFeed ? "Remove from Discover" : "Add to Discover" }}</p>
        <ion-alert
          class="text-black dark:text-white"
          :is-open="discoverAlertOpen"
          header="Confirm"
          message="Are you sure?"
          :buttons="discoverAlertButtons"
          @didDismiss="toggleDiscoverAlert"
        ></ion-alert>
      </ion-item>

      <ion-item
        v-if="!userData.ProfileEngagement.isFollowing"
        @click="userAction(displayUsername, userId, 'follow')"
      >
        <ion-icon slot="start" :icon="addCircleSharp" id="follow"></ion-icon>
        Follow @{{ userData.username }}
      </ion-item>
      <ion-item
        v-else-if="postType != 'AD'"
        @click="userAction(displayUsername, userId, 'unfollow')"
      >
        <ion-icon slot="start" :icon="removeCircleSharp" id="unfollow"></ion-icon>
        Unfollow
      </ion-item>
      <ion-item
        v-if="postType != 'AD'"
        @click="confirmAction(displayUsername, userId, 'mute')"
      >
        <ion-icon slot="start" :icon="volumeOffSharp" id="mute"></ion-icon>
        Mute
      </ion-item>
      <ion-item
        v-if="postType != 'AD'"
        @click="confirmAction(displayUsername, userId, 'block')"
      >
        <ion-icon slot="start" :icon="warningSharp" id="block"></ion-icon>
        Block
      </ion-item>
      <ion-item v-if="postType != 'AD'" @click="openAlert(true)">
        <ion-icon slot="start" :icon="flagOutline" id="present-alert"></ion-icon>
        Report Post
      </ion-item>
      <ion-item
        v-if="globalStore.isParler"
        @click.stop.prevent="copyPostUrlAndShowToast(id)"
      >
        <ion-icon slot="start" :src="share" class="text-black dark:text-white text-xl" />
        Share
      </ion-item>
      <ion-item
        v-if="groupId && isCurrentUserGroupOwner"
        @click="groupAdminModalOpen = true"
      >
        <ion-icon slot="start" class="text-red-500" :icon="trashBinSharp"></ion-icon>
        <p class="text-red-500">Remove</p>
        <ion-alert
          class="text-black dark:text-white"
          :is-open="groupAdminModalOpen"
          header="Remove Post from Group"
          message="Are you sure you want to delete this post? This action cannot be undone."
          :buttons="groupAdminModalButtons"
          @didDismiss="groupAdminModalOpen = false"
        ></ion-alert>
      </ion-item>
      <ion-item v-else-if="isCurrentUserEmployeeAndModerator" @click="openAdminPopover">
        <ion-icon slot="start" :src="adminIcon"></ion-icon>
        <p>Admin</p>
      </ion-item>
      <ion-popover
        :is-open="adminOpen"
        @didDismiss="adminOpen = false"
        :event="adminEvent"
        :dismiss-on-select="true"
        side="left"
        alignment="start"
      >
        <ion-list class="text-xs cursor-pointer select-none" nested lines="none">
          <ion-item>
            <p class="text-black dark:text-white">Admin Actions</p>
          </ion-item>
          <ion-item @click="markSensitive">
            <ion-icon slot="start" :icon="isSensitive ? removeCircle : addCircle" />
            <p>Sensitive</p>
          </ion-item>
          <ion-item @click="markTrolling">
            <ion-icon slot="start" :icon="isTrolling ? removeCircle : addCircle" />
            <p>Web Only</p>
          </ion-item>
          <ion-item
            v-if="globalStore.isParler"
            @click.stop.prevent="copyPostUrlAndShowToast(id)"
          >
            <ion-icon
              slot="start"
              :src="share"
              class="text-black dark:text-white text-xl"
            />
            Share
          </ion-item>
          <ion-item @click="toggleAdminModal" class="text-red-500">
            <ion-icon slot="start" :icon="trashBinSharp"></ion-icon>
            <p class="text-xs">Delete</p>
          </ion-item>
        </ion-list>
        <ion-alert
          class="text-black dark:text-white"
          :is-open="adminModalOpen"
          header="Admin Delete Post"
          message="Are you sure you want to delete this post? This action cannot be undone."
          :buttons="adminModalButtons"
          @didDismiss="toggleAdminModal"
        ></ion-alert>
      </ion-popover>
    </ion-list>
  </ion-popover>
  <div v-if="showShare" class="absolute top-0 right-2 w-full z-50">
    <div class="bg-white m-2 dark:bg-black p-4 w-full space-y-2 rounded-lg">
      <h2>Copy this link:</h2>
      <div class="p-2">
        <input
          ref="popoverInput"
          class="w-full text-[0.8rem] rounded-lg text-black"
          :value="`https://app.${domain}/post/${id}`"
          readonly
          @click.stop.prevent="selectLinkText"
        />
      </div>
      <button @click="closeShare">Close</button>
    </div>
  </div>
  <ion-alert
    class="text-black dark:text-white"
    :is-open="confirmAlertOpen"
    :header="confirmHeader"
    :message="confirmMessage"
    :buttons="confirmButtons"
    :inputs="confirmInputs"
    @didDismiss="handleDismissConfirmAlert"
  >
  </ion-alert>
  <ion-alert
    :is-open="isOpen"
    header="Alert"
    class="text-primary"
    message="Choose the report type!"
    :buttons="alertButtons"
    :inputs="alertInputs"
    @didDismiss="openAlert(false)"
  ></ion-alert>
  <ion-toast
    :is-open="toastOpen"
    :message="toastMessage"
    :duration="toastDuration"
    :color="toastColor"
    @didDismiss="closeToast"
  ></ion-toast>
</template>
<script setup>
import { IonIcon, IonItem, IonList, IonPopover, IonAlert, IonToast } from "@ionic/vue";
import {
  ellipsisHorizontalOutline,
  flagOutline,
  addCircleSharp,
  removeCircleSharp,
  volumeOffSharp,
  warningSharp,
  trashBinSharp,
  compassOutline,
  removeCircle,
  addCircle,
} from "ionicons/icons";
import { usePostStore } from "@/stores/post";
import { ref, nextTick, computed } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/users";
import { useGlobalStore } from "@/stores/global";
import { useAdminStore } from "@/stores/admin";
import { useRoute } from "vue-router";
import share from "@/assets/parler/share.svg";
import adminIcon from "@/assets/moderation_icon.svg";

const emits = defineEmits(["click"]);

const props = defineProps({
  id: String,
  userId: String,
  username: String,
  repostUserId: String,
  repostUsername: {
    type: String,
    default: null,
  },
  repostId: String,
  postType: String,
  parentId: String,
  groupId: String,
});

const authStore = useAuthStore();
const postStore = usePostStore();
const globalStore = useGlobalStore();
const adminStore = useAdminStore();
const popoverOpen = ref(false);
const adminOpen = ref(false);
const deletePostEvent = ref(null);
const following = ref(false);
const userStore = useUserStore();
const confirmAlertOpen = ref(false);
const confirmHeader = ref(""); // Confirmation alert header
const confirmMessage = ref(""); // Message to be shown in the confirmation alert
const currentAction = ref(""); // Store the current action (mute/block)
const currentUsername = ref(""); // Store the username for later use
const currentUserId = ref(""); // Store the user ID for later use
const unfollowCheckbox = ref(false);
const alertData = ref("");
const displayUsername = computed(() => props.username || props.repostUsername);
const displayUserId = computed(() => props.userId || props.repostUserId);
const adminModalOpen = ref(false);
const discoverAlertOpen = ref(false);
const route = useRoute();
const groupAdminModalOpen = ref(false);
const adminEvent = ref(null);

const removePostFromGroup = async () => {
  try {
    await postStore.deletePostFromGroup(props.groupId, props.id);
    groupAdminModalOpen.value = false;
  } catch (error) {
    console.error(error);
    // Handle error here
  }
};

const isSensitive = computed(() => {
  return postStore.postsCache[props.id]?.isSensitive || false;
});

const isTrolling = computed(() => {
  return postStore.postsCache[props.id]?.isTrolling || false;
});

const markSensitive = async () => {
  try {
    await adminStore.sensitiveAndTrollAction(props.id, "NSFW", !isSensitive.value);
  } catch (error) {
    console.error(error);
    // Handle error here
  }
};

const markTrolling = async () => {
  try {
    await adminStore.sensitiveAndTrollAction(props.id, "Troll", !isTrolling.value);
  } catch (error) {
    console.error(error);
    // Handle error here
  }
};

const isCurrentUserGroupOwner = computed(() => {
  return (
    postStore.groups[props.groupId]?.user.userId === authStore.currentUser.ulid || false
  );
});

const discoverAlertButtons = [
  { text: "Cancel", role: "cancel" },
  { text: "OK", role: "confirm", handler: discoverToggle },
];

const toggleDiscoverAlert = () => {
  discoverAlertOpen.value = !discoverAlertOpen.value;
  popoverOpen.value = false;
};

const adminModalButtons = [
  { text: "Cancel", role: "cancel" },
  { text: "OK", role: "confirm", handler: adminDeletePost },
];

const groupAdminModalButtons = [
  { text: "Cancel", role: "cancel" },
  { text: "OK", role: "confirm", handler: removePostFromGroup },
];

const toggleAdminModal = () => {
  adminModalOpen.value = !adminModalOpen.value;
};

const userData = computed(() => {
  return userStore.users[props.userId];
});

const popoverInput = ref(null);
const showShare = ref(false);

// Toast related states
const toastOpen = ref(false);
const toastMessage = ref("");
const toastDuration = ref(2000);
const toastColor = ref("");

const onDiscoverFeed = computed(() =>
  Boolean(globalStore.currentFeed === "influencers" && route.name === "home")
);

const apiHost = import.meta.env.VITE_API_HOST;
const domain = apiHost.substring(apiHost.indexOf(".") + 1);

// Function to attempt copying and show popover on failure
function copyPostUrlAndShowToast(postId) {
  const postUrl = `https://app.${domain}/post/${postId}`;
  navigator.clipboard
    .writeText(postUrl)
    .then(() => {
      showToast("Link copied to clipboard!", "success");
      popoverOpen.value = false;
    })
    .catch((err) => {
      console.error("Failed to copy:", err);
      // Show the popover if clipboard API fails
      showShare.value = true;
      popoverOpen.value = false;
    });
}

function closeShare() {
  showShare.value = false;
}

function selectLinkText() {
  if (popoverInput.value) {
    popoverInput.value.select();
  }
}

const isCurrentUserEmployeeAndModerator = computed(() => {
  return (
    authStore.currentUser.badges &&
    authStore.currentUser.badges.includes("employee") &&
    authStore.currentUser.badges.includes("moderator") &&
    props.postType !== "AD"
  );
});

const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("employee")
  );
});

async function adminDeletePost() {
  try {
    await adminStore.deletePost(props.id);
    popoverOpen.value = false;
  } catch (error) {
    console.error(error);
    // Handle error here
  }
}

const confirmInputs = computed(() => {
  if (following.value) {
    popoverOpen.value = false;
    return [
      {
        type: "checkbox",
        label: "Would you also like to unfollow?",
        value: "unfollow",
      },
    ];
  }
  return [];
});

function handleDismissConfirmAlert() {
  confirmAlertOpen.value = false;
  // Reset states to ensure proper reactivity
  currentAction.value = "";
  currentUsername.value = "";
  currentUserId.value = "";
  unfollowCheckbox.value = false;
}

function openPopover(e) {
  deletePostEvent.value = e;
  popoverOpen.value = true;
}

function openAdminPopover(e) {
  adminEvent.value = e;
  adminOpen.value = true;
}

let userActionMessage = ref(""); // Toast Message on userAction

async function openPostMenu(e, userId) {
  await nextTick(); // Wait for the DOM to update
  openPopover(e);
}

function discoverToggle() {
  if (onDiscoverFeed.value) {
    adminStore.removeFromDiscovery(props.repostId);
  } else {
    adminStore.addToDiscovery(props.id);
  }
}

function pastTense(action) {
  switch (action) {
    case "follow":
      return "followed";
    case "unfollow":
      return "unfollowed";
    case "mute":
      return "muted";
    case "block":
      return "blocked";
    default:
      return action;
  }
}

async function userAction(username, userId, action) {
  const muteBlockCoolDown = userStore.getMuteBlockCoolDown(userId);

  if ((action === "mute" || action === "block") && muteBlockCoolDown) {
    const now = new Date().getTime();
    const totalMinutesSinceLastAction = (now - muteBlockCoolDown) / (1000 * 60); // Get total difference in minutes

    const totalCooldownHours = 72;
    const totalCooldownMinutes = totalCooldownHours * 60;
    const remainingMinutes = totalCooldownMinutes - totalMinutesSinceLastAction;
    const remainingHours = Math.floor(remainingMinutes / 60);
    const remainingMins = Math.round(remainingMinutes % 60);

    userStore.removeUser(displayUserId.value);

    if (totalMinutesSinceLastAction < totalCooldownMinutes) {
      showToast(
        `Cannot ${action} ${username}. Cooldown of ${remainingHours} hours and ${remainingMins} minutes remain.`,
        "danger"
      );
      return;
    }
  }

  try {
    let response;

    if (action === "unfollow") {
      await userStore.unfollowUser(username, props.userId);
    } else if (action === "follow") {
      await userStore.followUser(username, props.userId);
    } else {
      const requestOptions = getRequestOptions(username, action);

      response = await apiBackendAuthAxios(requestOptions);
    }

    showToast(`${username} successfully ${pastTense(action)}`, "success");

    popoverOpen.value = false;

    if (action === "block" || action === "unfollow" || action === "mute") {
      postStore.removePostsFromCache(
        props.repostUserId ? props.repostUserId : props.userId
      );

      if (action === "block") {
        userStore.users[props.userId].ProfileEngagement.isBlockedByYou = true;
      }
    }
  } catch (error) {
    console.error("error", error);
    showToast(`Failed to ${action} ${username}. Please try again.`, "danger");
  } finally {
    popoverOpen.value = false;
  }
}

// Define the HTTP method and endpoint based on the action
function getRequestOptions(username, action) {
  switch (action) {
    case "follow":
    case "mute":
    case "block":
      return { method: "PUT", url: `/profile/${username}/${action}` };

    case "unfollow":
      return { method: "DELETE", url: `/profile/${username}/follow` };

    default:
      throw new Error(`Unsupported action: ${action}`);
  }
}

const confirmAction = (username, userId, action) => {
  confirmHeader.value = `Confirm ${action.toUpperCase()}?`;
  confirmMessage.value = `Are you sure you want to ${action} @${username}?`;
  currentAction.value = action; // Store the current action for later use
  currentUsername.value = username;
  currentUserId.value = userId;
  confirmAlertOpen.value = true; // Open the confirmation alert
};

const confirmButtons = [
  { text: "Cancel", role: "cancel" },
  {
    text: "OK",
    role: "confirm",
    handler: async () => {
      await userAction(currentUsername.value, currentUserId.value, currentAction.value);
      if (unfollowCheckbox.value) {
        await userAction(currentUsername.value, currentUserId.value, "unfollow");
      }
      handleDismissConfirmAlert();
    },
  },
];

async function reportPost(violationType) {
  // If the post is a repost, report the original post
  let id = props.id;

  apiBackendAuthAxios
    .put("/posts/flag", {
      ulid: id,
      violationType: violationType,
    })
    .then(() => {
      popoverOpen.value = false;
      showToast("Post Reported!", "success");
    })
    .catch((error) => {
      console.error("error", error);
    });
  postStore.removePostFromCache(id);
  if (props.repostId) {
    postStore.removePostFromCache(props.repostId);
  }
}

const isOpen = ref(false);
const alertButtons = [
  { text: "Cancel", role: "cancel" },
  { text: "OK", role: "confirm", handler: reportPost },
];
const alertInputs = [
  { label: "Bot", type: "radio", value: "automation_or_bot" },
  {
    label: "Criminal Solicitation",
    type: "radio",
    value: "criminal_solicitation",
  },
  { label: "Doxing", type: "radio", value: "doxing" },
  { label: "Fraud", type: "radio", value: "fraud" },
  {
    label: "Pornographic or Explicit Content",
    type: "radio",
    value: "pornographic_and_explicit_content",
  },
  { label: "Impersonation", type: "radio", value: "impersonation" },
  { label: "Incitement", type: "radio", value: "incitement" },
  { label: "IP Theft", type: "radio", value: "intellectual_property" },
  {
    label: "Sexualization of Minors",
    type: "radio",
    value: "sexualization_of_minors",
  },
  { label: "Spam", type: "radio", value: "spam" },
  { label: "Terrorism", type: "radio", value: "terrorism" },
  { label: "Threats", type: "radio", value: "threats" },
  {
    label: "Unsolicited Advertisements",
    type: "radio",
    value: "unsolicited_advertisements",
  },
];

const openAlert = (state) => {
  isOpen.value = state;
};

function showToast(message, color) {
  toastMessage.value = message;
  toastColor.value = color;
  toastOpen.value = true;
}

function closeToast() {
  toastOpen.value = false;
}
</script>
<style scoped>
.text-primary {
  color: var(--primary-color);
}

:deep(.block-mute-confirm),
:deep(.alert-button-role-cancel) {
  background-color: white !important;
  color: black !important;
}
:deep(.dark .block-mute-confirm) {
  background-color: black !important;
  color: white !important;
}
</style>
