import { defineStore } from "pinia";
import { useUserStore } from "./users";
import { usePostStore } from "./post";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { useAuthStore } from "@/stores/auth.js";

export const useAdminStore = defineStore("admin", {
  state: () => ({
    // Store properties
    violationTypes: [],
  }),
  actions: {
    async loginAdmin() {
      //to get token
      const authStore = useAuthStore();
      // Get the domain without the port
      const url = new URL(window.location.origin);
      let domainWithoutPort = url.hostname;
      // Remove the 'app' subdomain
      const parts = domainWithoutPort.split(".");
      if (parts[0] === "app") {
        parts.shift();
        domainWithoutPort = parts.join(".");
      }
      //actually set the cookie session_id with the token
      document.cookie = `session_id=${authStore.accessToken}; path=/; domain=${domainWithoutPort}`;
      window.open(`https://admin.${domainWithoutPort}/login-session`, "_blank");
    },

    async deletePost(ulid) {
      try {
        await apiBackendAuthAxios.delete("/moderation/post", {
          data: {
            ulid: ulid,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async banUser(ulid) {
      const userStore = useUserStore();
      const postStore = usePostStore();
      try {
        // Sending a POST request to ban a user
        const response = await apiBackendAuthAxios.post(
          "/moderation/profile/ban",
          { ulid }
        );
        // Fetch updated user data after ban
        await userStore.fetchUsersFromApi([ulid]);
        // Clear user's posts from the store
        postStore.removePostsFromCache(ulid);
        // Return response data to check the operation's success status
        return response.data;
      } catch (error) {
        console.error("Error banning user:", error);
        throw error; // Rethrow to handle the error in the caller
      }
    },

    async unbanUser(ulid) {
      const userStore = useUserStore();
      try {
        // Sending a POST request to unban a user
        const response = await apiBackendAuthAxios.delete(
          "/moderation/profile/ban",
          { data: { ulid: ulid } }
        );
        // Fetch updated user data after unban
        await userStore.fetchUsersFromApi([ulid]);
        // Return response data to check the operation's success status
        return response.data;
      } catch (error) {
        console.error("Error unbanning user:", error);
        throw error; // Rethrow to handle the error in the caller
      }
    },
    async addOrRemoveBadge(username, badgeName, method) {
      try {
        const response = await apiBackendAuthAxios({
          url: `/admin/profile/${username}/badges`,
          method,
          data: JSON.stringify({ badge: badgeName }),
          headers: { "Content-Type": "application/json" },
        });

        return response.data;
      } catch (error) {
        console.error(`Failed to add/remove badge: ${error.message}`);
        throw error;
      }
    },

    async fetchViolationTypes() {
      try {
        const response = await apiBackendAuthAxios.get("/settings/violations");
        this.violationTypes = response.data.data;
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    },

    async moderationRemoveFlag(postId) {
      try {
        const response = await apiBackendAuthAxios.delete(
          `/moderation/post/flag`,
          {
            data: {
              postULID: postId,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async moderationAction(postId, userId, violationName, moderationAction) {
      // Find the violation object based on the violationName provided
      const violation = this.violationTypes.find(
        (v) => v.name === violationName
      );

      if (!violation) {
        throw new Error("Violation type not recognized");
      }

      // Set default action and points
      let action = moderationAction ?? "removeContent";
      let points = violation.points;

      // Check if the violation type is NSFW and adjust the action accordingly
      if (violationName === "nsfw") {
        action = "applyNSFW";
      } else if (violationName === "troll") {
        action = "applyTroll";
      }

      // Prepare the data for the backend request
      const requestData = {
        postULID: postId,
        userULID: userId,
        action: action,
        points: points,
        violation: violationName,
      };

      try {
        const response = await apiBackendAuthAxios.post(
          `/moderation/post/action/${action}`,
          requestData
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async sensitiveAndTrollAction(postId, type, add) {
      const postStore = usePostStore();
      const action = add ? `apply${type}` : `remove${type}`;

      try {
        const response = await apiBackendAuthAxios.post(
          `/moderation/post/action/${action}`,
          {
            postULID: postId,
            action: action,
          }
        );
        let actionType = type === "NSFW" ? "isSensitive" : "isTrolling";
        postStore.postsCache[postId][actionType] = add;
        postStore.updatePostInDB(postStore.postsCache[postId]);
        console.log("SensitiveAndTrollAction:", postId, type, add);
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async addToDiscovery(postId) {
      try {
        const response = await apiBackendAuthAxios.post(
          "/employee/post/discover",
          {
            ulid: postId,
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async removeFromDiscovery(repostId) {
      const postStore = usePostStore();

      try {
        const response = await apiBackendAuthAxios.delete(
          "/employee/post/discover",
          {
            data: {
              ulid: repostId,
            },
          }
        );

        console.log("removeFromDiscovery:", repostId);

        // Remove the post from the cache
        await postStore.removePostFromCache(repostId);

        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async moderationRemoveViolation(postId) {
      const requestData = {
        postULID: postId,
      };

      try {
        const response = await apiBackendAuthAxios.post(
          `/moderation/post/action/restoreContent`,
          requestData
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },

  persist: true,
});
