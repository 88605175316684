<template>
  <div
    :key="`carousel-${id}`"
    class="relative ImageCarousel overflow-hidden max-h-[50vh]"
    ref="mediaContainer"
  >
    <!-- Button to go to the previous media -->
    <button
      class="absolute top-2/4 -translate-y-2/4 left-0 opacity-60 z-20 cursor-pointer select-none"
      @click.stop.prevent="goPrevious"
    >
      <ion-icon :icon="caretBack" class="text-7xl previous-arrow" />
    </button>
    <div
      v-if="hasPostData && currentVirtualIndex === 0"
      class="overflow-hidden"
    >
      <PostEmbeds
        :post-data="postData"
        :inCarousel="true"
        :postInView="postInView"
      />
    </div>

    <div
      v-else
      ref="imgRef"
      class="relative flex items-top justify-center"
      :style="{ height: height + 'px' }"
    >
      <!-- Display video if it's the current media -->
      <VideoPlayer
        v-if="
          isMediaType(images[currentImageIndex]?.url, 'video') ||
          mediaTypes[currentImageIndex] === 'video/mp4'
        "
        :postId="id"
        :videoUrl="currentVideoUrl"
        :height="height"
        :singleVideo="false"
        :lastWatched="images[currentImageIndex]?.lastWatched || 0"
        :index="currentImageIndex"
        class="rounded-lg"
      />

      <!-- Display image if it's the current media -->
      <img
        v-else
        :src="images[currentImageIndex]?.url + `?width=${imageWidth}`"
        class="max-w-full cursor-pointer select-none rounded-lg"
        :class="[
          mediaOnly ? 'full-size' : 'object-contain',
          isOgImage ? 'rounded-b-none' : '',
        ]"
        alt=""
      />
      <div
        v-if="
          !isMediaType(images[currentImageIndex]?.url, 'video') &&
          currentImageIndex !== -1
        "
        class="absolute inset-0 m-auto cursor-pointer"
        :style="{
          height: '100%',
          width: '30%',
          left: '30%',
          transform: 'translateX(-50%)',
        }"
        @click.stop.prevent="isOgImage ? openLink() : openModal()"
      ></div>
    </div>
    <!-- Button to go to the next media -->
    <button
      class="absolute top-2/4 -translate-y-2/4 right-0 opacity-60 cursor-pointer select-none z-50"
      @click.stop.prevent="goNext"
    >
      <ion-icon :icon="caretForward" class="text-7xl next-arrow" />
    </button>
  </div>
  <div
    v-if="isOgImage"
    @click="openLink"
    class="p-2 bg-gray-100 rounded-b-lg space-y-2 dark:bg-zinc-950 cursor-pointer select-none"
  >
    <p class="px-2 m-2 line-clamp-1 text-gray-500 font-bold">
      {{ postTitle }}
    </p>
    <p class="line-clamp-2 overflow-hidden px-2 text-[0.5rem]">
      {{ ogDesc }}
    </p>
  </div>
  <!-- Modal to display images, only shown when current media is an image -->
  <ImageModal
    v-if="
      isModalVisible &&
      isMediaType(images[currentImageIndex].url, 'image') &&
      currentImageIndex !== -1
    "
    :isModalVisible="isModalVisible"
    :modalContentUrls="images.map((img) => img.url)"
    :currentImageIndex="currentImageIndex"
    @closeModal="closeModal"
    @nextImage="goNext"
    @previousImage="goPrevious"
  />
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  nextTick,
} from "vue";
import { IonIcon, IonImg, createGesture } from "@ionic/vue";
import { caretBack, caretForward } from "ionicons/icons";
import ImageModal from "../media/ImageModal.vue";
import VideoPlayer from "../media/VideoPlayer.vue";
import PostEmbeds from "../media/PostEmbeds.vue";

const props = defineProps({
  images: {
    type: Array,
    required: false,
  },
  mediaOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  mediaTypes: {
    type: Array,
    required: false,
  },
  postData: {
    type: Object,
    required: false,
  },
  id: {
    type: String,
    required: false,
  },
  imageWidth: {
    type: Number,
    required: false,
    default: 0,
  },
  postTitle: {
    type: String,
    default: "",
  },
  ogDesc: {
    type: String,
    default: "",
  },
  ogLink: {
    type: String,
    default: "",
  },
  postInView: {
    type: Boolean,
    default: false,
  },
});

const hasPostData = computed(() => Object.keys(props.postData).length !== 0);
let currentImageIndex = ref(hasPostData.value ? -1 : 0);
const currentVirtualIndex = computed(() =>
  hasPostData.value ? currentImageIndex.value + 1 : currentImageIndex.value
);

const currentVideoUrl = computed(() => {
  return props.images[currentImageIndex.value]?.url;
});

const isOgImage = computed(() => {
  return currentVirtualIndex.value === 0 && props.ogDesc;
});

const openLink = () => {
  window.open(props.ogLink, "_blank");
};

const imgRef = ref(null);
const gesture = ref(null);
const mediaContainer = ref(null);
const emit = defineEmits(["media-height"]);

const height = computed(() => {
  let maxWidth = 0;

  if (window.innerWidth >= 768) {
    maxWidth = window.innerWidth * 0.5;
  } else {
    maxWidth = window.innerWidth;
  }

  let maxHeight = window.innerHeight * 0.5;

  const media = props.images[currentImageIndex.value];

  if (media?.heightPx && media?.widthPx) {
    // Calculate the aspect ratio
    const aspectRatio = media.widthPx / media.heightPx;

    // Calculate the height if the image were at full width
    const calculatedHeight = maxWidth / aspectRatio;

    // Get the minimum of the calculated height and half of the screen height
    maxHeight = Math.min(calculatedHeight, window.innerHeight * 0.5);
  }

  return maxHeight;
});

function isMediaType(url, type) {
  if (!url) return false;

  const imageExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
  const videoExtensions = ["mp4", "mov", "avi", "wmv"];

  const extension = url.split(".").pop().toLowerCase();

  if (type === "image") {
    return imageExtensions.includes(extension);
  } else if (type === "video") {
    return videoExtensions.includes(extension);
  }

  return false;
}

// Emit the height whenever it changes
watch(height, (newHeight) => {
  emit("media-height", newHeight);
});

// Calculate the maximum media height
onMounted(async () => {
  if (imgRef.value && imgRef.value.$el) {
    gesture.value = createGesture({
      el: imgRef.value.$el,
      gestureName: "swipe-next-previous",
      threshold: 15,
      canStart: () => true,
      onStart: () => {},
      onMove: () => {},
      onEnd: (detail) => {
        if (detail.deltaX < -150) {
          // Swipe left
          goNext();
        } else if (detail.deltaX > 150) {
          // Swipe right
          goPrevious();
        }
      },
    });
    gesture.value.enable();
  }
});

onBeforeUnmount(() => {
  if (gesture.value) {
    gesture.value.destroy();
  }
});

// Function to go to the previous media
const goPrevious = () => {
  if (currentImageIndex.value === 0 && !hasPostData.value) {
    // If on the first slide without PostEmbeds, loop to the last image
    currentImageIndex.value = props.images.length - 1;
  } else if (currentImageIndex.value === -1) {
    // If on the first slide and PostEmbeds is present, loop to the last image
    currentImageIndex.value = props.images.length - 1;
  } else {
    // Otherwise, just go to the previous image
    currentImageIndex.value--;
  }
};

// Function to go to the next media
const goNext = () => {
  currentImageIndex.value++;
  if (currentImageIndex.value >= props.images.length) {
    // Reset to show PostEmbeds as the first slide or the first image if PostEmbeds is not present
    currentImageIndex.value = hasPostData.value ? -1 : 0;
  }
};

// Modal state
const isModalVisible = ref(false);

const openModal = () => {
  // Open the modal
  isModalVisible.value = true;
};

// Function to close the modal
function closeModal() {
  isModalVisible.value = false;
}
</script>

<style scoped>
.full-size {
  /* Full-size styles for media */
  width: 100%;
  /* Full width */
  height: auto;
  /* Height auto to maintain aspect ratio */
  object-fit: cover;
  /* Cover to ensure the media covers the area */
}

/* Styles for image and video tags specifically */
.max-w-full {
  width: 100%;
  /* Full width */
  height: auto;
  /* Auto height to maintain aspect ratio */
  object-fit: contain;
  /* Contain ensures the entire media is visible without being cut off */
}

ion-icon.next-arrow {
  color: var(--secondary-color);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

ion-icon.previous-arrow {
  color: var(--primary-color);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

ion-img::part(image) {
  border-radius: 0.5rem;
}
</style>
