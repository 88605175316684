<template>
  <div v-if="violation" class="w-full mt-2 text-sm sm:text-md md:text-xl">
    <!-- Loop through violations -->
     <div v-if="violation?.postFlagStatus === 'appeal'">
      <div class="bg-red-700 rounded-lg flex justify-center items-center space-x-2 text-white" :id="'reported' + violation.id"><ion-icon :icon="alertCircle"/><span>Appeal{{ disableAppeal ? ' Disabled' : '' }}</span></div>
      <p class="text-gray-600 dark:text-white">
        User's reason for appeal:</p>
        <p class="text-primary border rounded-lg p-2">{{ violation.postFlagAppealText }}</p>
     </div>
    <div class="flex items-center w-full">
      <ion-icon
        v-if="violation?.postFlagStatus !== 'appeal'"
        :icon="flagSharp"
        :id="'reported' + violation.id"
        class="align-bottom"
      />
      <ion-popover :trigger="'reported' + violation.id" side="top" alignment="center">
        <div
          v-for="(flag, index) in violation.violations"
          :key="index"
          class="p-2 text-lg w-full"
        >
          <p class="text-red-500" v-if="violation.postFlagStatus === 'appeal'">Current Violation </p>
           {{ formatName(flag.name) }} {{ violation.postFlagStatus !== "appeal" ? `: ${flag.count}` : ':' }} <span class="" v-if="violation.postFlagStatus === 'appeal'"> {{ violationTypes.find(type => type.name === flag.name)?.points }} Points </span>
           <p v-if="violation.postFlagStatus === 'appeal'"><span class="text-red-500">Moderated by: </span>{{ violation.moderatedBy === violation.username ? 'Hive' : violation.moderatedBy }} </p>
          </div>
      </ion-popover>
      <div v-if="!disableAppeal" class="ml-4 flex items-center justify-between text-center w-full">
        <p>Is this {{ violation?.postFlagStatus === 'appeal' ? 'still a' : 'a' }} violation?</p>
        <div class="flex space-x-4">
          <ion-checkbox
            class="cursor-pointer select-none"
            @ionChange="toggleViolationOption(true)"
            color="danger"
            :checked="selectedViolationOption === true"
            style="--size: 16px; --checkmark-width: 1px; --border-width: 1px"
          >
            Yes
          </ion-checkbox>
          <ion-checkbox
            class="cursor-pointer select-none"
            @ionChange="toggleViolationOption(false)"
            color="danger"
            :checked="selectedViolationOption === false"
            style="--size: 16px; --checkmark-width: 1px; --border-width: 1px"
          >
            No
          </ion-checkbox>
        </div>
      </div>
    </div>
    <div v-if="selectedViolationOption === true && !disableAppeal" class="ml-1 align-middle w-full">
      <div class="flex justify-center items-center p-3 pb-1 w-full">
        <ion-select
          placeholder="Violation type"
          v-model="selectedViolationType"
          interface="action-sheet"
          class="select-full-width bg-gray-100 text-black dark:bg-black text-md dark:text-white dark:hover:text-white pl-1 pr-1 rounded-lg cursor-pointer select-none"
          @ionChange="openReasonAccordion"
          style="
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <ion-select-option
            class="text-sm dark:text-white"
            v-for="type in violationTypes"
            :key="type.name"
            :value="type.name"
          >
            {{ formatName(type.name) }}{{ type.points ? `(${type.points} points)` : "" }}
          </ion-select-option>
        </ion-select>
        <ion-icon
          v-if="selectedViolationType"
          title="Violation Description"
          :icon="informationCircleSharp"
          :id="'selected' + violation.id"
          class="text-3xl pl-2"
        />
      </div>
      <ion-popover :trigger="'selected' + violation.id">
      <div class="p-2 text-sm sm:text-md md:text-xl">
        <p>{{ getViolationTypeDescription }}</p>
      </div>
    </ion-popover>
    </div>
    
  </div>
  <Transition>
    <div v-if="reasonAccordion" class="p-1 pt-2">
      <ion-textarea
        :auto-grow="true"
        :counter="true"
        maxlength="2000"
        :spellcheck="true"
        autocapitalize="sentences"
        v-model="violationReason"
        class="bg-white text-black dark:bg-black dark:text-white w-full rounded-md p-1 mb-2"
        rows="3"
        :placeholder="getPlaceholderText()"
        @keydown.enter.prevent="submitViolationOnEnter"
      />
      <div class="flex items-center justify-end space-x-3">
        <button @click="closeReasonAccordion"
          class="cursor-pointer select-none p-1 mt-1 transition duration-500 ease-in-out bg-gray-300 text-white rounded-lg text-base focus:outline-none"
          > Cancel </button>
        <button
        :class="violationReason.length < 5 ? 'bg-gray-500' : 'bg-primary'"  
        class="cursor-pointer select-none mt-1 transition duration-500 ease-in-out p-1 text-white rounded-lg text-base focus:outline-none flex justify-center items-center"
          @click="violationOrNot()"
          :disabled="violationReason.length < 5 || disableAppeal"
        >
        Submit
          <!-- <ion-icon title="Submit" :src="moderationIcon" /> -->
        </button>
      </div>
    </div>
  </Transition>
  <ion-toast
    :message="toastMessage"
    :duration="toastDuration"
    :color="toastColor"
    :isOpen="toastOpen"
  />
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { usePostStore } from "@/stores/post";
import { useAuthStore } from "@/stores/auth";
import {
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonPopover,
  IonTextarea,
  IonButton,
  IonToast,
} from "@ionic/vue";
import moderationIcon from "@/assets/moderation_icon.svg";
import { informationCircleSharp, flagSharp, alertCircle } from "ionicons/icons";
import { useAdminStore } from "../../stores/admin";

const props = defineProps({
  parentItem: Object,
});

const postStore = usePostStore();
const adminStore = useAdminStore();
const authStore = useAuthStore();
const violationTypes = computed(() => adminStore.violationTypes);
const selectedViolationOption = ref(null);
const selectedViolationType = ref("");
const reasonAccordion = ref(false);
const violationReason = ref("");
const toastMessage = ref("");
const toastDuration = ref(0);
const toastColor = ref("");
const toastOpen = ref(false);

onMounted(() => {
  if (adminStore.violationTypes.length === 0) {
    fetchViolationTypes();
  }
});

const disableAppeal = computed(() => {
  return Boolean(authStore.currentUser.name === violation.value.moderatedBy);
});

const violation = computed(() => {

  return postStore.violationsCache[props.parentItem.id];
});

const getViolationTypeDescription = computed(() => {
  // Retrieve the description based on selectedViolationType
  const selectedType = violationTypes.value.find(
    (type) => type.name === selectedViolationType.value
  );
  return selectedType ? selectedType.description : "";
});

const getPlaceholderText = () => {
  if (selectedViolationOption.value === true) {
    return `Why do you think this is ${formatName(selectedViolationType.value)} ?`;
  }
  return "Why do you think this is NOT a violation?";
};

async function fetchViolationTypes() {
  try {
    adminStore.fetchViolationTypes();
  } catch (error) {
    console.error(error);
    // Handle error if needed
  }
}

function formatName(name) {
  // Capitalize first letter and replace underscores with spaces
  return name.charAt(0).toUpperCase() + name.slice(1).replace(/_/g, " ");
}

let toastQueue = [];
let isToastShowing = ref(false);
let toastTimeout = null;

function showToast(message, color) {
  toastQueue.push({ message, color });
  if (!isToastShowing.value) {
    displayNextToast();
  }
}

function displayNextToast() {
  if (toastQueue.length > 0) {
    let toast = toastQueue.shift(); // Get the next toast in the queue
    toastMessage.value = toast.message;
    toastColor.value = toast.color;
    toastDuration.value = 2000; // Adjust as needed
    toastOpen.value = true;
    isToastShowing.value = true;

    toastTimeout = setTimeout(() => {
      toastOpen.value = false;
      isToastShowing.value = false;
      displayNextToast(); // Attempt to display the next toast after a delay
    }, toastDuration.value);
  }
}

function toggleViolationOption(option) {
  selectedViolationOption.value = option;

  // If "No" is selected, open the reason accordion
  if (!option) {
    reasonAccordion.value = true;
    selectedViolationType.value = "";
    return;
  }
  reasonAccordion.value = false;
}

function openReasonAccordion() {
  reasonAccordion.value = true;
}

function closeReasonAccordion() {
  reasonAccordion.value = false;
  violationReason.value = "";
  selectedViolationOption.value = null;
}

function submitViolationOnEnter(event) {
  if (event.key === "Enter" && !event.shiftKey) {
    violationOrNot();
  }
}

function violationOrNot() {
  if (selectedViolationOption.value === true) {
    submitViolation();
  } else if (violation.value.postFlagStatus === "appeal") {
    removeViolation();
  } else {
    removeFlag()
  }
}

// Function to submit the violation report
async function submitViolation() {
  if (violationReason.value) {
    if (selectedViolationType.value) {

      try {
        // Submit the violation report
        await adminStore.moderationAction(
          props.parentItem.id,
          props.parentItem.user.userId,
          selectedViolationType.value,
          violation.value.postFlagStatus === "appeal" ? "denyAppeal" : "removeContent",
        )

        let violationMessage = selectedViolationType.value
          ? `Violation moderated successfully`
          : `Violation report removed successfully`;
        showToast(violationMessage, "success");

        //Remove violation from the cache and the feedmap
        delete postStore.violationsCache[props.parentItem.id];
        postStore.removePostFromCache(props.parentItem.id);
        const violationIndex = postStore.feedMaps.violations.findIndex(
          (violation) => violation === props.parentItem.id
        );
        if (violationIndex !== -1) {
          postStore.feedMaps.violations.splice(violationIndex, 1);
        }
      } catch (error) {
        // Since `response` is not accessible here, log and handle the error object directly
        console.error(error);
        let errorMessage = `An error occurred while reporting the violation: ${
          error.response ? error.response.data.message : error.message
        }`;
        showToast(errorMessage, "danger");
      }
    }
  } else {
    showToast("Please select a violation type and provide a reason", "danger");
  }
}

// Function to remove the violation report
async function removeViolation() {
  try {
    // Remove the violation report
    await adminStore.moderationRemoveViolation(props.parentItem.id);

    //Remove violation from the cache and the feedmap
    delete postStore.violationsCache[props.parentItem.id];
    const violationIndex = postStore.feedMaps.violations.findIndex(
      (violation) => violation === props.parentItem.id
    );
    if (violationIndex !== -1) {
      postStore.feedMaps.violations.splice(violationIndex, 1);
    }
  } catch (error) {
    // Since `response` is not accessible here, log and handle the error object directly
    console.error(error);
    let errorMessage = `An error occurred while reporting the violation: ${
      error.response ? error.response.data.message : error.message
    }`;
    showToast(errorMessage, "danger");
  }
}

async function removeFlag() {
  try {
    // Remove the violation report
    await adminStore.moderationRemoveFlag(props.parentItem.id);

    //Remove violation from the cache and the feedmap
    delete postStore.violationsCache[props.parentItem.id];
    const violationIndex = postStore.feedMaps.violations.findIndex(
      (violation) => violation === props.parentItem.id
    );
    if (violationIndex !== -1) {
      postStore.feedMaps.violations.splice(violationIndex, 1);
    }
  } catch (error) {
    // Since `response` is not accessible here, log and handle the error object directly
    console.error(error);
    let errorMessage = `An error occurred while reporting the violation: ${
      error.response ? error.response.data.message : error.message
    }`;
    showToast(errorMessage, "danger");
  }
}

onBeforeUnmount(() => {
  clearTimeout(toastTimeout);

  // Close the toast if it's open
  if (isToastShowing.value) {
    toastOpen.value = false;
    isToastShowing.value = false;
  }
});
</script>
<style scoped>
ion-checkbox::part(label) {
  /* Apply your custom styles here */
  margin-right: 0.2rem;
  /* Adjust the margin as needed */
}

.select-full-width {
  max-width: 100% !important;
  width: 100% !important;
  padding-left: 20px !important;
  text-align: center !important;
}

.bg-primary {
  background-color: var(--primary-color);
} 
</style>
