<template>
  <ion-page class="bg-zinc-50 dark:bg-black">
    <div class="flex flex-col md:flex-row h-full md:w-full md:overflow-hidden">
      <!-- Left Menu, hidden on mobile devices -->
       <div class="hidden md:block md:w-1/5">
        <LeftMenu  @scroll-top="scrollToTop" />
      </div>
      <!-- Feed Template, takes full width on mobile and central column on larger screens -->
      <div class="flex-1 md:w-3/5">
         <MainHeader
          v-if="toolBar"
          class="z-50"
          :currentPage="feedType"
          @refreshFeed="refreshFeed = true"
          @scroll-top="scrollToTop"
          :showHeader="toolBar"
          :showCreatePost="showCreatePost"
          :currentFeed="feedType"
          />
        <FeedTemplate
          ref="feedTemplate"
          :feedType="feedType"
          :refreshFeedLogo="refreshFeed"
          @refreshComplete="refreshFeed = false"
          @toolBar="toggleToolbar"
          @toggleVisibility="createPostVisibility"
        />
      </div>
      <!-- Right Menu, hidden on mobile devices, shown on larger screens -->
      <div class="hidden md:block md:w-1/5">
        <RightMenu />
      </div>
    </div>
    <!-- Toolbar for mobile devices -->
     <div class="md:hidden">
      <ParlerToolbar
        :showToolBar="toolBar"
        @refreshFeed="refreshFeed = true"
        @scroll-to-top="scrollToTop"
      />
    </div>
  </ion-page>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { IonPage } from "@ionic/vue";
import { onIonViewDidEnter } from "@ionic/vue";
import MainHeader from "@/components/MainHeader.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import ParlerToolbar from "@/components/tenantcomponents/parler/ParlerToolbar.vue";
import RightMenu from "@/components/RightMenu.vue"; 
import { useGlobalStore } from "@/stores/global";
import { usePostStore } from "@/stores/post";
import FeedTemplate from "@/components/FeedTemplate.vue";

const refreshFeed = ref(false);
const globalStore = useGlobalStore();
const toolBar = ref(true);
const postStore = usePostStore();

const defaultFeed = computed(() => globalStore.settings.default_feed);

onIonViewDidEnter(() => {});

// Watch for changes in query parameters
const route = useRoute();
const router = useRouter();
watch(
  () => route.query,
  (newQuery) => {
    if (newQuery.feedType) {
      globalStore.currentFeed = newQuery.feedType;
      router.replace("/feed");
    }
  }
);

function scrollToTop() {
  feedTemplate.value.refreshFeed();
}

const showCreatePost = ref(true);

function createPostVisibility(show) {
  showCreatePost.value = show;
}

const feedType = computed(() => globalStore.currentFeed);
const content = ref(null);
const feedTemplate = ref(null);

function toggleToolbar(toggle) {
  toolBar.value = toggle;
}
</script>
