<template>
  <div
    :key="`body-${parentItem?.id}`"
    class="w-full box-border relative"
    :class="(isSensitive && !showSensitiveContent) || isTrolling ? 'min-h-72' : 'h-auto'"
  >
    <p v-if="parentItem && parentItem.isDeleted">This post was deleted by the user.</p>
    <!-- Interstitial Overlay -->
    <button
      v-if="(isSensitive || isTrolling) && showSensitiveContent"
      @click="toggleSensitiveContent"
      title="Hide Sensitive Content"
      class="cursor-pointer select-none absolute -top-12 right-8 rounded-full show"
      :class="isTrolling ? 'h-12 p-1' : 'size-8'"
    >
      <div v-if="isTrolling" class="flex justify-between items-center px-2 space-x-2">
        <ion-icon :icon="megaphoneSharp" class="text-3xl text-white" />
        <p class="text-xs text-center text-white">
          Parler advocates for counter speech over censorship.
        </p>
        <img src="@/assets/parler/troll-icon.png" class="size-6 mx-auto" />
      </div>
      <ion-icon v-else :icon="eyeOffSharp" class="text-2xl text-white pt-1" />
    </button>
    <div
      class="h-auto w-full"
      v-if="parentItem && (isSensitive || isTrolling) && !showSensitiveContent"
    >
      <div
        v-if="parentItem && (isSensitive || isTrolling) && !showSensitiveContent"
        class="interstitial-overlay opacity-40 md:opacity-100"
      >
        <!-- Empty div for the overlay background -->
      </div>
      <!-- Interstitial Content -->
      <div v-if="parentItem && (isSensitive || isTrolling) && !showSensitiveContent">
        <!-- Interstitial Content -->
        <div class="interstitial-content w-full">
          <div class="flex-col justify-around space-y-4 space-x-4">
            <ion-icon
              v-if="isTrolling"
              :icon="globeOutline"
              class="text-8xl text-white"
            />
            <h1 class="font-bold text-4xl">
              {{ isTrolling ? "Web-Only Content" : "Sensitive Content" }}
            </h1>
            <!-- Display the button to view content only if not in the native PWA wrapper -->
            <button
              v-if="!isApp || isSensitive"
              @click="toggleSensitiveContent"
              class="cursor-pointer select-none"
            >
              View Anyways
            </button>
            <p v-if="isTrolling" class="text-xs text-center px-4">
              Parler advocates for counter speech over censorship
              <span v-if="isApp"
                >. However, in accordance with app store policies, this material is
                restricted within the app.</span
              ><span v-else
                >and does not endorse or condone the views expressed in this
                content.</span
              >
            </p>
            <div v-if="isApp && isTrolling">
              <p class="text-xs mt-2">You may access this content on our website.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content-container"
      :class="{
        'blur-content': (isSensitive || isTrolling) && !showSensitiveContent,
      }"
    >
      <p
        v-if="
          parentItem && parentItem.body !== ' ' && !parentItem.isDeleted && !mediaOnly
        "
        class="ion-padding pt-0 ps-0 pe-0 ms-0 text-base font-medium text-gray-600 dark:text-white overflow-hidden w-full"
        :class="{ 'pt-3 pb-2 font-normal': isCommentDetails }"
        @click="navigateToContent(parentItem.id)"
      >
        <!-- Loop through the lines -->
        <template v-if="parentItem.body">
          <template
            v-for="(line, lineIndex) in splitIntoLines(displayText)"
            :key="`line-${lineIndex}`"
          >
            <!-- Loop through the words in each line -->
            <template
              v-for="(word, wordIndex) in normalizeWhitespace(line).split(' ')"
              :key="`word-${wordIndex}`"
            >
              <template v-if="isTag(word) && links[word]">
                <template v-if="links[word] !== undefined">
                  <!-- Render as a tag -->
                  <span
                    @click.stop="navigateToLink(links[word])"
                    class="cursor-pointer select-none tag-link my-0 break-normal"
                    >{{ formatTag(word) }}</span
                  >
                </template>
                <template v-else>
                  <!-- Render as normal text if it's an @tag without a valid link -->
                  <span class="break-normal">{{ word }}</span>
                </template>
              </template>
              <template v-else-if="isURL(word)">
                <span
                  target="_blank"
                  rel="noopener noreferrer"
                  @click.stop.prevent="navigateToUrl(word)"
                  class="cursor-pointer select-none tag-link break-normal"
                >
                  {{ word }}
                </span>
              </template>

              <template v-else>
                <!-- Check if it's the last word of the last line and showMore is true -->
                <span class="break-normal whitespace-nowrap">{{ word }}</span>
              </template>

              <!-- Add a space between words except for the last word -->
              <span v-if="wordIndex !== line.split(' ').length - 1">&nbsp;</span>
            </template>
            <!-- Add a line break after each line except for the last line -->
            <div
              class="m-0 p-0 ps-0 pe-0 cc-line-break pt-px"
              style="line-height: 0.05rem"
            >
              &nbsp;
            </div>
          </template>
        </template>
      </p>
    </div>
    <button
      v-if="showMoreButton"
      @click="toggleExpanded"
      class="tag-link cursor-pointer select-none"
    >
      {{ isExpanded ? "Show Less" : "Show More" }}
    </button>
    <!-- Show the "Show More" link if the body is not expanded -->
    <div
      v-if="
        parentItem?.images?.length > 0 || parentItem?.embedUrl || parentItem?.isProcessing
      "
      class="relative"
      @click="navigateToContent(parentItem?.id)"
    >
      <MediaContainer
        :parentItem="parentItem"
        :detailsShow="detailsShow"
        :mainPost="mainPost"
        :mediaOnly="mediaOnly"
        :longBody="showMoreButton"
        @navigate-to-content="navigateToContent"
        :postInView="postInView"
        :containerWidth="containerWidth"
        :ogDesc="ogDesc"
        :ogLink="ogLink"
      />
    </div>
  </div>
</template>

<script setup>
// @ts-check

import MediaContainer from "./MediaContainer.vue";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useRouter } from "vue-router";
import { IonIcon } from "@ionic/vue";
import { eyeOffSharp, megaphoneSharp, globeOutline } from "ionicons/icons";
import { replaceEncodingErrorChars } from "@/helpers";

const props = defineProps({
  parentItem: {
    type: Object,
    required: true,
  },
  detailsShow: {
    type: Boolean,
    required: false,
    default: false,
  },
  mainPost: {
    type: Boolean,
    required: false,
    default: false,
  },
  mediaOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  isReply: {
    type: Boolean,
    required: false,
    default: false,
  },
  postInView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isExpanded = ref(false);
const MAX_LENGTH = props.parentItem?.images?.length > 0 ? 150 : 500;
const isImageSquare = ref(false);

const showSensitiveContent = ref(false);
const isSensitive = computed(
  () => (props.parentItem?.isSensitive && !props.isReply) ?? false
);
const isTrolling = computed(() => props.parentItem?.isTrolling ?? false);

const containerWidth = computed(() => {
  return document.querySelector(".content-container")?.offsetWidth || 0;
});

const toggleSensitiveContent = () => {
  showSensitiveContent.value = !showSensitiveContent.value;
};

const isApp = computed(() => {
  return isIOSPWA.value || isAndroidPWA.value;
});

const isIOSPWA = computed(() => {
  return Boolean(window.webkit && window.webkit.messageHandlers);
});

//logic for android
const isAndroidPWA = computed(() => {
  return window.matchMedia("(display-mode: standalone)").matches;
});

const showMoreButton = computed(() => {
  // Explicitly cast the result to a boolean
  return Boolean(
    props.parentItem?.body &&
      props.parentItem?.body.length > MAX_LENGTH &&
      !props.detailsShow
  );
});

let previousScrollPosition = 0;

const toggleExpanded = () => {
  if (!isExpanded.value) {
    // Capture the current scroll position before expanding
    previousScrollPosition = window.scrollY;
  }

  isExpanded.value = !isExpanded.value;

  // Scroll back to the captured position smoothly
  window.scrollTo({ top: previousScrollPosition, behavior: "smooth" });
};

// Compute whether the post should be truncated
const shouldTruncate = computed(() => {
  const body = props.parentItem?.body || "";
  return (
    !isExpanded.value &&
    body.trim().length > 0 &&
    body.length > MAX_LENGTH &&
    !props.detailsShow
  );
});

const ogLink = ref("");
const ogDesc = ref("");

const displayText = computed(() => {
  const body = replaceEncodingErrorChars(props.parentItem?.body);

  if (!body) return "";

  let remainingText = body;

  // Extract the first URL
  const urlRegex = /(https?:\/\/[^\s]+)/;
  const match = body.match(urlRegex);
  ogLink.value = match ? match[0] : "";
  if (props.parentItem?.title) {
    // Extract text after '\n---\n\n'
    const parts = body.split("\n---\n\n");
    ogDesc.value = parts.length > 1 ? parts[1] : "";
    remainingText = parts.length > 1 ? parts[0] : body;
  }

  // Return the formatted text
  return shouldTruncate.value
    ? remainingText.substring(0, MAX_LENGTH) + "..."
    : remainingText;
});

const imageHeight = computed(() => {
  const height = props.parentItem.images[0].heightPx;

  const width = props.parentItem.images[0].widthPx;

  if (width > containerWidth.value) {
    return (containerWidth.value / width) * height;
  } else {
    return height;
  }
});

onMounted(async () => {
  if (props.parentItem?.body) {
    await generateLinks(props.parentItem?.body);
  }
});

onBeforeUnmount(() => {
  isExpanded.value = false;
});

const links = ref({});

async function generateLinks(text) {
  const words = text.split(/\s+/);
  const linksMap = {};

  for (const word of words) {
    if (isTag(word)) {
      // If it's a tag, resolve the link.
      linksMap[word] = await getTagLink(word);
    } else if (isURL(word)) {
      // If it's a URL, handle it immediately without the need to await.
      linksMap[word] =
        word.startsWith("http://") || word.startsWith("https://")
          ? word
          : `https://${word}`;
    }
  }
  links.value = linksMap;
}

const router = useRouter();

const navigateToLink = (link) => {
  router.push(link);
};

const navigateToUrl = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

function isTag(word) {
  // Regex to match $ followed by 3 or 4 letters
  const dollarTagRegex = /^\$[A-Za-z]{3,5}$/;

  // Check if the word is an @tag or #tag, or a $tag with the specific format
  const isTag = word.startsWith("@") || word.startsWith("#") || dollarTagRegex.test(word);

  // Check if there is more than just the tag
  const isMoreThanTag = word.length > (word[0] === "$" ? 6 : 2);

  return isTag && isMoreThanTag;
}

async function getTagLink(word) {
  if (word.startsWith("@")) {
    const username = word.substring(1).replace(/[^\w\s]+$/, "");
    try {
      // const exists = await userStore.checkUsernameCache(username, true);

      const exists = true;

      if (exists) {
        return `/${username}`;
      } else {
        return null; // Return null or some default value for non-existing usernames
      }
    } catch (error) {
      console.error(`Error checking username: ${error.message}`);
      return null;
    }
  } else if (word.startsWith("#")) {
    const tag = word.substring(1);
    const sanitizedTag = tag.replace(/[^\w\s]+$/, ""); // Remove trailing punctuation marks from the tag
    return `/hashtag/${encodeURIComponent(sanitizedTag)}`;
  } else if (word.startsWith("$")) {
    const ticker = word.substring(1);
    const sanitizedTicker = ticker.toUpperCase().slice(0, 5); // Convert to uppercase and limit to 5 characters
    return `/ticker/${encodeURIComponent(sanitizedTicker)}`;
  } else if (isURL(word)) {
    // Check if the word is a URL
    if (!word.startsWith("http://") && !word.startsWith("https://")) {
      // If it doesn't have a protocol prefix, add "https://"
      return `https://${word}`;
    } else {
      // If it already has a protocol prefix, return it as is
      return word;
    }
  }
}

function isURL(word) {
  // Early return if length is unreasonable
  if (word.length > 2000 || word.length < 3) {
    return false;
  }

  // Early return if string doesn't start with a common protocol
  if (!word.startsWith("http")) {
    return false;
  }

  // Regex pattern to match URLs
  const pattern = new RegExp(
    "^https?:\\/\\/" + // protocol
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*\\.)+[a-z]{2,}|" + // domain name
      "(\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%@_.~+&:]*)*" + // port and path
      "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );

  return pattern.test(word);
}

function formatTag(word) {
  if (word.startsWith("$")) {
    const ticker = word.substring(1);
    const formattedTicker = ticker.toUpperCase().slice(0, 5); // Convert to uppercase and limit to 5 characters
    return `$${formattedTicker}`;
  }
  return word;
}

const navigateToContent = (postId) => {
  router.push(`/post/${postId}`);
};
function normalizeWhitespace(text) {
  // Collapse multiple consecutive spaces into a single space, clean &nbsp;
  return text.replaceAll(/\s+/g, " ").replaceAll(/\&nbsp;/g, "");
}

function splitIntoLines(text) {
  // Split the text into lines by line break characters
  return text?.split("\n");
}
</script>

<style scoped>
.tag-link {
  color: var(--tertiary-color);
  /* Additional styling as needed */
}

ion-icon {
  --ionicon-stroke-width: 24px;
}

.content-container.blur-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  /* For Safari */
  z-index: 5;
  /* Below the interstitial overlay */
}

.interstitial-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border-radius: 0.5rem;
  backdrop-filter: blur(20px);
  /* Apply blur to the overlay */
  -webkit-backdrop-filter: blur(20px);
  /* For Safari */
  z-index: 10;
  /* Under the content */
  /* Adjust opacity as needed */
}

.interstitial-content {
  position: absolute;
  /* Positioned in relation to the viewport */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  /* Adjust width to fit content */
  text-align: center;
  justify-content: center;
  /* Adjust font size as needed */
  z-index: 15;
  /* Above the overlay */
  color: white;
  /* Font color */
}

.interstitial-content button {
  margin: auto;
  padding: 2px 20px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.show {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}
</style>
