<template>
  <transition name="new">
    <div
      v-if="newPostsAvailable"
      :class="toolbarHidden || feedType === 'group' ? 'top-4' : 'top-28 md:top-32'"
      class="custom-toast mb-6 z-50 cursor-pointer select-none"
      @click="newPostClose"
    >
      <div class="flex p-2">
        <ion-spinner v-if="isLoadingNew" name="dots" />
        <ion-icon v-else :icon="arrowUpOutline" class="p-1" />
        <div class="pl-2 pr-2 whitespace-nowrap">
          {{ newPostMessage }}
        </div>
        <div
          v-for="(avatar, index) in topAvatars"
          :key="avatar"
          :class="{ '-ml-1': index !== 0 }"
        >
          <div
            v-if="avatar"
            class="w-6 h-6 rounded-full border border-white overflow-hidden"
          >
            <UserAvatar :avatar="avatar" :small="true" />
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div v-if="refreshFeedLogo" class="loading-bar-container">
      <div class="loading-bar animate-pulse"></div>
    </div>
  </transition>
  <ion-content :scroll-y="false" class="justify-center">
    <ion-refresher
      v-if="scrolledTop"
      slot="fixed"
      :pull-factor="0.4"
      :pull-min="100"
      :pull-max="300"
      @ionRefresh="refreshFeed($event)"
      ref="refresher"
    >
      <ion-refresher-content
        :refreshing-spinner="null"
        class="flex ion-align-items-center bg-white dark:bg-black"
      >
        <ion-spinner class="text-black dark:text-white" name="lines" />
      </ion-refresher-content>
    </ion-refresher>
    <LoadingAnimation
      v-if="isLoading || (isLoadingMore && !feedPosts.length)"
      :feedType="feedType"
    />
    <DynamicScroller
      v-else-if="feedPosts.length > 0"
      :items="feedPosts ? feedPosts : []"
      :min-item-size="170"
      :prerender="20"
      :buffer="2000"
      class="pb-24 overscroll-none"
      :class="lockFeed ? 'overflow-hidden' : 'scroller'"
      @scroll="handleScroll"
      ref="feedContainer"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :key="item.id"
          :active="active"
          :size-dependencies="[item.images, item.postType, item.isRepostWithComment]"
          :data-index="index"
        >
          <div class="ml-2 mr-2">
            <template v-if="item.id === 'END'">
              <div
                class="text-gray-500 text-center m-auto dark:text-gray-400 border-t-2 pb-6"
              >
                End of Feed
              </div>
            </template>
            <template v-else :key="`post-${item.id}`">
              <PostDetails
                :parentItemId="item.id"
                :ref="item.id"
                :mediaOnly="mediaOnly"
                :detailsShow="false"
                :isModeration="feedType === 'violations'"
                @post-deleted="removePost"
                :feedType="feedType"
                :pinnedPostId="props.pinnedPost?.id"
              />
            </template>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <div v-else-if="noPostsLoaded" class="pt-8 absolute top-0 w-full">
      <div v-if="feedType === 'profile'" class="text-center">
        <div class="mb-4">
          <img :src="noPosts" class="mx-auto" />
        </div>
        <h2 class="font-bold text-xl mb-3">
          {{ isMuted ? "You muted this user." : "This user has no posts yet." }}
        </h2>
      </div>
      <div v-else-if="feedType === 'search'" class="text-center pt-12">
        <div class="mb-4">
          <img :src="searchIcon" class="mx-auto" />
        </div>
        <h2 class="font-bold text-xl mb-3">
          No posts found for {{ searchLabel }} {{ feedParams }}.
        </h2>
        <p class="text-gray-600">{{ searchContent }}</p>
      </div>
      <div v-else-if="feedType === 'trending'" class="text-center">
        <p class="text-[0.9rem] text-gray-500 pt-3 pb-3 border-b">
          No trending posts found.
        </p>
      </div>
      <div v-else-if="feedType === 'violations'" class="text-center">
        <p class="text-center text-gray-600 pt-4">No reported violations at this time.</p>
      </div>
      <div v-else-if="feedType === 'newUsers'" class="text-center mt-4">
        <div class="mb-1">
          <ion-icon :src="leaf" class="mx-auto text-green-700 text-7xl" />
        </div>
        <h2 class="font-bold text-xl mb-3">
          Welcome{{ globalStore.isParler ? " to Parler" : "" }}!
        </h2>
        <p class="text-gray-600">
          There are currently no posts from new users. Invite your friends and family to
          join{{ globalStore.isParler ? " Parler" : "" }}!
        </p>
      </div>
      <div v-else-if="feedType === 'group'" class="text-center">
        <div class="mb-4">
          <img :src="noPosts" class="mx-auto" />
        </div>
        <h2 class="font-bold text-xl mb-3">This group has no posts yet.</h2>
        <p class="text-gray-600">
          Be the first to post in this group and start the conversation!
        </p>
      </div>
      <div v-else-if="feedType === 'groups'" class="text-center">
        <div class="mb-4">
          <img :src="noPosts" class="mx-auto" />
        </div>
        <h2 class="font-bold text-xl mb-3">No group posts found.</h2>
        <p class="text-gray-600">
          Join a group and start posting to engage with other members.
        </p>
      </div>
      <div v-else class="text-center mt-4">
        <div class="mb-4">
          <img :src="noPosts" class="mx-auto" />
        </div>
        <h2 class="font-bold text-xl mb-3">
          Start Building Your Experience: Follow Someone!
        </h2>
        <p class="text-gray-600">
          Once you follow users, their posts will seamlessly populate your feed, ensuring
          you stay up-to-date with their latest content.
        </p>
        <p class="text-gray-600 mt-2">
          To expand your network, use the search functionality to find and follow other
          users.
        </p>
      </div>
    </div>
    <div
      class="flex absolute bottom-4 justify-center w-full"
      color="medium"
      v-if="isLoadingMore"
    >
      <ion-spinner />
    </div>
  </ion-content>

  <!-- <button
    @click="postStore.checkForNewPostsPusher()"
    class="border bg-red-500 rounded-md z-20"
  >
    TEST
  </button> -->
</template>

<script setup>
// @ts-check

import { watch, ref, computed, onBeforeMount, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  IonSpinner,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonContent,
} from "@ionic/vue";
import { arrowUpOutline, refresh } from "ionicons/icons";
import { usePostStore } from "@/stores/post";
import { useUserStore } from "@/stores/users";
import { useGlobalStore } from "@/stores/global";
import PostDetails from "../components/posts/PostDetails.vue";
import UserAvatar from "../components/UserAvatar.vue";
import LoadingAnimation from "../components/LoadingAnimation.vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { randomInsert } from "@/helpers/arrayHelper.js";
import searchIcon from "@/assets/parler/search_icon.svg";
import noPosts from "@/assets/parler/no_post_icon.svg";
import leaf from "@/assets/parler/leaf.svg";

const props = defineProps({
  feedType: {
    type: String,
    required: true,
  },
  isMuted: {
    type: Boolean,
    required: false,
    default: false,
  },
  feedParams: {
    type: [String, Object],
    required: false,
    default: null,
  },
  searchType: {
    type: [String, Object],
    required: false,
    default: null,
  },
  refreshFeedLogo: {
    type: Boolean,
    required: false,
    default: false,
  },
  mediaOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  pinnedPost: {
    type: Object,
    required: false,
    default: null,
  },
  profileId: {
    type: String,
    required: false,
    default: null,
  },
  lockFeed: {
    type: Boolean,
    default: false,
  },
});

// Variables for managing the state of the feed and infinite scroll
let isLoadingMore = ref(true);
let hasMoreItems = ref(true);
const postStore = usePostStore();
const userStore = useUserStore();
const globalStore = useGlobalStore();
const router = useRouter();
let lastPostPotition = 0;
let next_cursor = null; // next_cursor for pagination of posts from server
const pageSize = 5;
const feedContainer = ref(null);
const feedTypeLoading = ref(false);
const endOfFeed = ref(false);
const isLoading = ref(true);
const isLoadingNew = ref(false);
const toolbarHidden = ref(false);
const noPostsLoaded = ref(false);

const searchLabel = computed(() => {
  switch (props.searchType) {
    case "hashtag":
      return "#";
    case "username":
      return "@";
    case "ticker":
      return "$";
    case "posts":
      return "";
    default:
      return "";
  }
});

const searchContent = computed(() => {
  switch (props.searchType) {
    case "hashtag":
      return "Try searching for a different hashtag.";
    case "username":
      return "Try searching for a different username.";
    case "ticker":
      return "Try searching for a different ticker.";
    case "posts":
      return "Try searching for something else.";
    default:
      return "";
  }
});

const feedMapKey = computed(() => {
  let feedParam;
  if (props.feedType === "search") {
    feedParam = `${props.feedType}-${props.searchType}-${props.feedParams}`;
  } else if (props.feedType === "profile" && props.searchType === "media") {
    feedParam = `${props.feedType}-${props.feedParams}-media`;
  } else if (props.feedType === "profile" && props.searchType === "comments") {
    feedParam = `${props.feedType}-${props.feedParams}-comments`;
  } else if (props.feedType === "profile" || props.feedType === "group") {
    feedParam = `${props.feedType}-${props.feedParams}`;
  } else {
    feedParam = props.feedType;
  }
  return feedParam;
});

function removePost(id) {
  if (feedPostIds.value.includes(id)) {
    const index = postStore.feedMaps[feedMapKey.value].findIndex(
      (postId) => postId === id
    );
    postStore.feedMaps[feedMapKey.value].splice(index, 1);
  }
}

const feedPostIds = computed(() => {
  // Check if the feedMap for the feedParam exists and has length

  if (
    !postStore.feedMaps[feedMapKey.value] ||
    postStore.feedMaps[feedMapKey.value].length === 0
  ) {
    return [];
  }

  const feedMap = postStore.feedMaps[feedMapKey.value];

  if (feedMap.includes("END")) {
    endOfFeed.value = true;
  }

  return postStore.feedMaps[feedMapKey.value] || [];
});

const xPostFeed = computed(() => {
  return postStore.feedMaps["xposts"] || [];
});

const feedPosts = computed(() => {
  // Start with an empty array
  let computedFeed = [];

  // Prepend the pinned post if it exists and meets the conditions
  if (
    props.pinnedPost &&
    (!props.mediaOnly || (props.mediaOnly && props.pinnedPost.images.length > 0))
  ) {
    //remove pinned post if it is already in the feed
    const index = feedPostIds.value.indexOf(props.pinnedPost.id);
    if (index > -1) {
      feedPostIds.value.splice(index, 1);
    }
    computedFeed.unshift(props.pinnedPost);
  }

  let computedFeedIds = [];

  // if (feedPostIds.value.length > 10) {

  // if (postStore.feedMaps['xposts'].length === 0) {

  //   // postStore.fetchXposts()
  // }
  // mix in xposts
  // computedFeedIds = randomInsert(feedPostIds.value, xPostFeed.value, 4, 7);
  // } else {
  computedFeedIds = feedPostIds.value;

  // }

  // Append the rest of the posts
  if (props.feedType === "violations") {
    computedFeed = computedFeed.concat(
      computedFeedIds
        .map((postId) => postStore.violationsCache[postId])
        .filter((post) => post)
    );
  } else {
    // Append the rest of the posts
    computedFeed = computedFeed.concat(
      computedFeedIds.map((postId) => postStore.postsCache[postId]).filter((post) => post)
    );
  }

  if (endOfFeed.value && computedFeed.length > 0) {
    computedFeed.push({ id: "END" });
  }

  // Return the final array
  return computedFeed;
});

watch(
  () => feedPosts.value,
  async (newValue, oldValue) => {
    if (newValue.length > 0) {
      isLoading.value = false;
    }
  }
);

const suffix = computed(() => `${props.feedParams}-${props.searchType}`);

const feedKey = computed(() => {
  return props.feedType === "profile" || props.feedType === "search"
    ? `${props.feedType}-${suffix.value}`
    : props.feedType;
});

const emit = defineEmits(["refreshComplete", "toggleVisibility", "toolBar"]);

onBeforeMount(async () => {
  await loadFeed();
  if (props.feedType === "groups") {
    await postStore.fetchGroupList("myGroups");
  }
});

async function loadFeed() {
  if (props.feedType === "profile" && props.feedParams === null) {
    return;
  }

  isLoadingMore.value = true;

  try {
    // fetch data from server async
    let feedData = await postStore.fetchFeedData(
      props.feedType,
      props.feedParams,
      props.searchType
    );

    next_cursor = feedData.next_cursor;

    const posts = feedData.posts;

    if (posts.length === 0 || posts === null) {
      noPostsLoaded.value = true;
      return;
    }

    noPostsLoaded.value = false;

    // if (posts.length > 10 && postStore.feedMaps['xposts'].length === 0) {
    //   postStore.fetchXposts()

    // };

    if (next_cursor === null && feedMapKey.value) {
      postStore.feedMaps[feedMapKey.value].push("END");
      endOfFeed.value = true;
    }
  } catch (error) {
    console.error("Error fetching feed data:", error);
  } finally {
    isLoadingMore.value = false;
    isLoading.value = false;
  }
}

const newPostKey = computed(() => {
  if (props.feedType === "group") {
    return `${props.feedType}-${props.feedParams}`;
  }
  return props.feedType;
});

const newPosts = computed(() => {
  return postStore.newPosts[newPostKey.value] || [];
});

const newUlids = computed(() => {
  if (!newPosts.value) {
    return [];
  }

  return newPosts.value.map((post) => post.ulid) || [];
});

const newUserIds = computed(() => {
  if (!newPosts.value) {
    return [];
  }

  return newPosts.value.map((post) => post.userId) || [];
});

const topAvatars = computed(() => {
  if (!newUserIds.value) {
    return [];
  }

  const topUserIds = getTopUserIds(newUserIds.value);

  let isLoadingArray = [];

  topUserIds.forEach = async (id) => {
    if (userStore.getUsername(id) === "Loading...") {
      isLoadingArray.push(id);
    }

    userStore.fetchUsersByIds(isLoadingArray);
  };

  return topUserIds.map((id) => userStore.getAvatar(id));
});

const newPostsAvailable = computed(() => newPosts?.value?.length > 0);

const newPostsCount = computed(() => {
  if (!newPosts.value) {
    return 0;
  }
  return newPosts.value.length > 99 ? "99+" : newPosts.value.length.toString();
});

const newPostMessage = computed(() => {
  return newPostsCount.value > 1 ? `${newPostsCount.value} New Posts` : "New Post";
});

function getTopUserIds(userIds) {
  const count = {};

  userIds.forEach((id) => (count[id] = (count[id] || 0) + 1));

  const sortedIds = Object.keys(count).sort((a, b) => count[b] - count[a]);
  return sortedIds.slice(0, 3);
}

// Function to add ULIDs to the correct feedMap
function updateFeedMap(newUlids) {
  if (!postStore.feedMaps[newPostKey.value]) {
    postStore.feedMaps[newPostKey.value] = [];
  }
  // Combine new ULIDs with existing ones and remove duplicates
  const uniqueUlids = Array.from(
    new Set([...newUlids.value, ...postStore.feedMaps[newPostKey.value]])
  );

  // Sort the ULIDs in reverse order
  uniqueUlids.sort((a, b) => b.localeCompare(a));

  // Update the feedMap with the sorted and unique ULIDs
  postStore.feedMaps[newPostKey.value] = uniqueUlids;

  //remove END and put it at the end
  const index = postStore.feedMaps[newPostKey.value].indexOf("END");
  if (index > -1) {
    postStore.feedMaps[newPostKey.value].splice(index, 1);
    postStore.feedMaps[newPostKey.value].push("END");
  }
}

const newPostClose = async () => {
  isLoadingNew.value = true;

  if (newPosts.value.length > 20) {
    postStore.newPosts[newPostKey.value] = [];
    refreshFeed(null);
    return;
  }

  try {
    newUlids.value.sort((a, b) => b.id - a.id);

    //remove posts ulids that are already in the feed
    newUlids.value.filter((id) => !feedPostIds.value.includes(id));

    // Update the feedMap with the new ULIDs
    updateFeedMap(newUlids);

    const newPosts = await postStore.fetchPostsByIds(newUlids.value, true);

    // Get parent grandparent and root Ulids
    const parentIds = newPosts.map((post) => post.parentUlid).filter((id) => id !== null);
    const grandparentIds = newPosts
      .map((post) => post.grandparentUlid)
      .filter((id) => id !== null);
    const rootIds = newPosts.map((post) => post.rootUlid).filter((id) => id !== null);

    // Combine and dedupe the Ulids
    const combinedIds = [...parentIds, ...grandparentIds, ...rootIds];
    const uniqueIds = [...new Set(combinedIds)];

    // Fetch the posts for the unique Ulids
    await postStore.fetchPostsByIds(uniqueIds);

    postStore.newPosts[newPostKey.value] = [];

    scrollToTop();
  } catch (error) {
    console.error("Error appending new posts to the feed and updating lastUlid:", error);
  } finally {
    isLoadingNew.value = false;
  }
};

let ulidQueue = []; // Queue for ULIDs to process

let lastTriggerIndex = null; // Tracks the last index that triggered loading

let triggerIndex = 10; // Initial trigger index for the first load

watch(
  () => postStore.visiblePosts,
  (newValue, oldValue) => {
    const feedLength = feedPostIds.value.length;

    if (feedLength > 19) {
      const triggerId = feedPostIds.value[triggerIndex];
      const lastId = feedPostIds.value[feedLength - 1];

      //remove oldValue to only send new posts to queue
      oldValue.forEach((id) => {
        const index = newValue.indexOf(id);
        if (index > -1) {
          newValue.splice(index, 1);
        }
      });

      // addMultipleToQueue(newValue);

      if (
        (newValue.includes(triggerId) && triggerIndex !== lastTriggerIndex) ||
        (newValue.includes(lastId) && !isLoadingMore.value)
      ) {
        lastTriggerIndex = triggerIndex; // Update the last trigger

        loadMorePosts();
      }
    }
  },
  { deep: true }
);

let firstQueue = true;

function addMultipleToQueue(ids) {
  ids.forEach((id) => addToQueue(id));
}

function addToQueue(id) {
  ulidQueue.push(id);
  //dedupe the queue
  ulidQueue = [...new Set(ulidQueue)];

  if (firstQueue && ulidQueue.length >= 5) {
    firstQueue = false;
    processRenderUpdates();
  } else if (ulidQueue.length >= 20) {
    processRenderUpdates(); // Corrected to actually call the function
  }
}

async function processRenderUpdates() {
  const uniqueIds = ulidQueue;
  ulidQueue = []; // Clear the queue

  if (uniqueIds.length > 0) {
    await postStore.fetchPostEngagements(uniqueIds);
  }
}

/**
 * Scrolls the feed container to the top.
 *
 *
 */
async function scrollToTop() {
  if (feedContainer.value?.$el) {
    feedContainer.value.$el.scrollTop = 0;
  }
}

watch(
  () => props.refreshFeedLogo,
  async (newValue) => {
    if (newValue) {
      refreshFeed(null);
    }
  }
);

watch(
  () => props.feedType,
  async (newFeedType, oldFeedType) => {
    if (newFeedType !== oldFeedType) {
      feedTypeLoading.value = true;
      scrollToTop();
      isLoadingMore.value = true;

      // fetch initial posts from cache for new feed type
      if (postStore.feedMaps[feedMapKey.value]) {
        await postStore.checkForNewPostsPusher();
        newPostClose();
      } else {
        await loadFeed();
      }
      hasMoreItems.value = true;

      isLoadingMore.value = false;
      feedTypeLoading.value = false;
    }
  }
);
watch(
  () => props.searchType,
  async (newSearchType, oldSearchType) => {
    if (newSearchType !== oldSearchType) {
      feedTypeLoading.value = true;
      isLoadingMore.value = true;
      // fetch initial posts from cache for new feed type
      await loadFeed();

      hasMoreItems.value = true;
      scrollToTop();
      feedTypeLoading.value = false;
      isLoadingMore.value = false;
    }
  }
);

watch(
  () => props.feedParams,
  async (newFeedParams, oldFeedParams) => {
    if (newFeedParams !== oldFeedParams) {
      feedTypeLoading.value = true;
      isLoadingMore.value = true;
      // fetch initial posts from cache for new feed type
      await loadFeed();

      hasMoreItems.value = true;
      scrollToTop();
      feedTypeLoading.value = false;
      isLoadingMore.value = false;
    }
  }
);

/**
 * Asynchronously refreshes the feed content.
 *
 * @param {Event} event - The event that triggers the refresh.
 * @returns {Promise} - A promise that resolves once the refresh is complete.
 */
const refreshFeed = async (event) => {
  scrollToTop();

  postStore.feedMaps[feedMapKey.value] = [];

  await loadFeed();

  await postStore.fetchPostEngagements(feedPostIds.value.slice(0, 20));

  // Let Ionic know that your refresher is done, so the refresh animation can end
  if (event?.target && typeof event.target.complete === "function") {
    event.target.complete();
  }

  emit("refreshComplete");

  isLoadingMore.value = false;
};

const scrolledTop = ref(false);
let lastScrollTop = 0; // Track the last scroll position
let scrollDownCounter = 0; // Counter for consecutive scrolls down
let scrollUpCounter = 0; // Counter for consecutive scrolls up
const consecutiveScrollsRequired = 5; // Number of consecutive scrolls required to trigger

function handleScroll(event) {
  // return;

  if (!event.target) {
    return;
  }
  const { scrollTop, scrollHeight, clientHeight } = event.target;

  // Calculate the distance from the bottom
  const scrollBottom = scrollHeight - scrollTop - clientHeight;

  // Calculate 30% of the clientHeight
  const triggerDistance = clientHeight * 0.3;

  if (scrollTop === 0) {
    scrolledTop.value = true;
    // Reset counters when reaching the top
    scrollDownCounter = 0;
    scrollUpCounter = 0;
    emit("toolBar", true);
    emit("toggleVisibility", true);
    toolbarHidden.value = false;
  } else if (scrollBottom <= triggerDistance && !isLoadingMore.value) {
    // Load more posts when 30% or less away from the bottom
    if (next_cursor !== null) {
      loadMorePosts();
    }
  } else {
    scrolledTop.value = false;
    emit("toggleVisibility", false);
  }

  // Determine scroll direction and update counters
  if (scrollTop > lastScrollTop) {
    // Scrolling down
    scrollDownCounter++;
    scrollUpCounter = 0; // Reset scroll up counter

    if (scrollDownCounter >= consecutiveScrollsRequired) {
      emit("toolBar", false);
      toolbarHidden.value = true;
      scrollDownCounter = 0; // Reset counter after triggering
    }
  } else if (scrollTop < lastScrollTop) {
    // Scrolling up
    scrollUpCounter++;
    scrollDownCounter = 0; // Reset scroll down counter

    if (scrollUpCounter >= consecutiveScrollsRequired) {
      emit("toolBar", true);
      toolbarHidden.value = false;
      scrollUpCounter = 0; // Reset counter after triggering
    }
  }

  // Handle specific behavior for profile feed
  if (globalStore.currentFeed !== "influencers" || props.feedType === "profile") {
    emit("toggleVisibility", scrollTop === 0);
  }

  // Update lastScrollTop for the next scroll event
  lastScrollTop = scrollTop;
}

/**
 * Loads more posts from the post store and appends them to the feed.
 *
 * @param {Event} ev - The event that triggers the method.
 * @return {Promise} - A promise that is resolved when the posts are loaded and appended to the feed.
 */

let lastCalledTime = null;
const throttleDuration = 3000; // 10 seconds in milliseconds

async function loadMorePosts() {
  isLoadingMore.value = true;

  // Check if the function was called within the throttle duration
  if (lastCalledTime && Date.now() - lastCalledTime < throttleDuration) {
    isLoadingMore.value = false;
    return;
  }

  lastCalledTime = Date.now();
  // Exit the function if next_cursor is null
  if (next_cursor === null) {
    isLoadingMore.value = false;
    return;
  }

  lastPostPotition += postStore.sliceFeed;
  let returnData = await postStore.fetchFeedData(
    props.feedType,
    props.feedParams,
    props.searchType,
    lastPostPotition,
    null,
    pageSize,
    next_cursor
  );
  let posts = returnData.posts;

  // set next_cursor for pagination
  next_cursor = returnData.next_cursor;

  if (next_cursor === null) {
    postStore.feedMaps[feedMapKey.value].push("END");
    endOfFeed.value = true;
  }
  // Filter out posts that already exist in feedPostIds
  const uniqueNewPosts = posts.filter(
    (postUlid) => !feedPostIds.value.includes(postUlid)
  );

  // Add only unique new posts to feed
  feedPostIds.value.push(...uniqueNewPosts.map((post) => post.id));

  triggerIndex = feedPostIds.value.length - 20;

  // complete infinite scroll
  // ev.target.complete();

  isLoadingMore.value = false;
}

onBeforeUnmount(() => {
  postStore.visiblePosts = [];

  firstQueue = true;
});

// Expose the function if needed
defineExpose({
  refreshFeed,
  scrollToTop,
});
</script>

<style scoped lang="scss">
.scroller {
  height: 100%;
}

.new-enter-active,
.toast-leave-active {
  transition: all 0.5s ease;
}

.new-enter,
.toast-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.new-enter-to,
.toast-leave {
  opacity: 1;
  transform: translateY(0);
}

.custom-toast {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  background: linear-gradient(
    91.53deg,
    var(--primary-color),
    var(--secondary-color) 100%
  );
  border-radius: 20px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  color: white;
  max-width: 70%;
}

.text-primary {
  color: var(--primary-color);
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.1);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1);
  }
}

.heartbeat {
  animation: heartbeat 2s ease-in-out infinite;
}

.loading-bar-container {
  width: 100%;
  top: var(--header-height);
  /* Adjust this based on your header's height */
  z-index: 10;
  /* Ensure this is above your page content but below any fixed headers */
}

.loading-bar {
  height: 2px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  position: absolute;
  height: 2px;
  left: -100%;
  width: 100%;
  background-color: var(--primary-color);
  /* Your accent color */
  animation: loadingBar 2s linear infinite;
}

@keyframes loadingBar {
  0% {
    left: -100%;
  }

  50% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}
ion-content {
  .feed-wrapper {
    .vue-recycle-scroller {
      overflow: hidden;
    }
  }

  &.min {
    .vue-recycle-scroller {
      overflow: auto;
    }
  }
}
</style>
