<template>
  <div
    @click="logoClick"
    :class="{
      'flex-row items-center': small,
      'flex-col items-center justify-center': !small,
    }"
    class="logo-wrapper flex cursor-pointer select-none"
  >
    <div
      v-if="loading && !globalStore.isParler"
      :class="{ 'spinner-wrapper flex items-center justify-center': loading }"
    >
      <ion-spinner name="circles" class="custom-spinner" />
    </div>

    <img
      v-else-if="isKGA"
      src="/tenant/kga/kga_cup.png"
      alt="Tenant Logo"
      class="object-cover rounded-full"
      :class="{
        'w-11 h-11': small,
        'w-[150px] h-[150px] mb-4': !small,
      }"
    />
    <div
      v-else
      :class="{
        'w-10 h-10': !globalStore.isParler && small,
        'h-9': globalStore.isParler && small && !searchClicked,

        'w-[150px] h-[150px] mb-4': !small && !globalStore.isParler,
        'w-[150px]': !small && globalStore.isParler && login,
        'icon rounded-full custom-gradient text-white': notWhollyOrParler,
      }"
    >
      <WhollyLogo v-if="isWholly" />
      <ParlerLogo
        v-else-if="globalStore.isParler"
        :loading="loading"
        :login="login"
        :searchClicked="searchClicked"
        :mainHeader="mainHeader"
      />

      <!-- Else, show HTML logo -->
      <div v-else class="flex items-center justify-center h-full">
        <span :class="{ 'text-lg': small, 'text-6xl': !small }" class="font-bold"
          >P</span
        >
      </div>
    </div>

    <div
      v-if="home && !globalStore.isParler"
      :class="{
        'ml-1 text-xs text-black dark:text-white': small,
        'text-center text-2xl md:text-3xl pl-2 text-black dark:text-white': !small,
      }"
    >
      <span class="font-light">Parler</span>
      <span class="font-bold"></span>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { IonSpinner } from "@ionic/vue";
import WhollyLogo from "./wholly/WhollyLogo.vue";
import ParlerLogo from "./parler/ParlerLogo.vue";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  home: {
    type: Boolean,
    default: false,
  },
  searchClicked: {
    type: Boolean,
    default: false,
  },
  mainHeader: {
    type: Boolean,
    default: false,
  },
  login: {
    type: Boolean,
    default: false,
  },
});

const globalStore = useGlobalStore();

const isWholly = ref(import.meta.env.VITE_IS_WHOLLY === "true" ? true : false);

const isKGA = ref(import.meta.env.VITE_IS_KGA === "true" ? true : false);

const notWhollyOrParler = ref(!isWholly.value && !globalStore.isParler);

const emit = defineEmits(["logo-click"]);

const logoClick = () => {
  emit("logo-click");
};
</script>
<style scoped>
.spinner-wrapper {
  /* Center the spinner vertically */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

ion-spinner {
  --color: var(--tertiary-color);
  --font-size: 36px;
}

.custom-gradient {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}
</style>
