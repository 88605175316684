<template>
  <div class="relative">
    <div class="absolute left-0 flex flex-col h-full justify-between">
      <ion-list lines="none" class="cursor-pointer select-none p-5 pt-10">
        <ion-item @click="navigateToProfile" :class="{ primary: urlIsProfile }">
          <ion-avatar
            :class="{ primary: urlIsProfile }"
            class="avatar h-11 w-11 md:h-13 md:w-13"
          >
            <div class="h-10 w-10 md:h-12 md:w-12">
              <UserAvatar :avatar="avatar" />
            </div>
          </ion-avatar>
          <ion-label class="font-sf-pro font-bold text-menu leading-menu pl-3"
            >Profile</ion-label
          >
        </ion-item>
        <ion-item
          v-if="globalStore.isParler"
          @click="navigateToHome"
          :class="{ primary: urlIsHome }"
        >
          <ion-icon class="w-10 ion-padding-end" :src="home" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu">Home</ion-label>
        </ion-item>
        <ion-item @click="navigateToSettings" :class="{ primary: urlIsSettings }">
          <ion-icon class="w-10 ion-padding-end" :icon="cog" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Settings</ion-label
          >
        </ion-item>
        <ion-item @click="emit('openInvite')">
          <ion-icon class="w-10 ion-padding-end" :src="share" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Invite</ion-label
          >
        </ion-item>
        <ion-item @click="navigateToConnections" :class="{ primary: urlIsConnections }">
          <ion-icon class="w-10 ion-padding-end" :src="connectionsIcon" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Connections</ion-label
          >
        </ion-item>
        <ion-item @click="emit('open')">
          <ion-icon class="w-10 ion-padding-end" :icon="mail" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Feedback</ion-label
          >
        </ion-item>
        <ion-item v-if="globalStore.isParler" @click="openParlerStore">
          <ion-icon class="w-10 ion-padding-end" :icon="bag" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu">Shop</ion-label>
        </ion-item>
        <ion-item @click="openParlerPay">
          <ion-icon class="w-10 ion-padding-end" :src="parlerPay" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >ParlerPay</ion-label
          >
        </ion-item>
        <ion-item
          v-if="hasViolations"
          @click="navigateToViolations"
          :class="{ primary: urlIsViolations }"
        >
          <ion-icon class="w-10 ion-padding-end" :src="moderationIcon" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Violations</ion-label
          >
        </ion-item>
        <ion-item
          v-if="!globalStore.isParler || isCurrentUserAdmin || isCurrentUserEmployee"
          @click="loginAdmin"
        >
          <ion-icon class="w-10 ion-padding-end" :icon="compass" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Admin</ion-label
          >
        </ion-item>
        <ion-item @click="openKnowledgeCenter()">
          <ion-icon class="w-10 ion-padding-end" :icon="helpCircleOutline" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Help Center</ion-label
          >
        </ion-item>
      </ion-list>
      <ion-list lines="none" class="cursor-pointer p-5">
        <ion-item>
          <ion-icon
            v-if="!dark_mode"
            class="w-5 ion-padding-end"
            :icon="moonSharp"
            :class="{
              white: dark_mode,
            }"
          />
          <ion-icon
            v-else
            class="w-10 ion-padding-end"
            :icon="sunnySharp"
            :class="{
              white: dark_mode,
            }"
          />
          <ion-toggle v-model="dark_mode" class="float-right" />
        </ion-item>
        <ion-item @click="logout()">
          <ion-icon class="w-8 ion-padding-end" :icon="logOutSharp" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu"
            >Logout</ion-label
          >
        </ion-item>
        <div
          class="flex items-center space-x-2 text-black dark:text-white"
          @click="forceRefresh"
        >
          <ion-label class="text-xs">v.3.0.0.0-{{ date }}</ion-label>
          <ion-icon :icon="refresh" class="text-xl cursor-pointer" />
        </div>
        <p class="text-[.6rem] text-black dark:text-white">{{ version }}</p>
      </ion-list>
    </div>
  </div>
</template>

<script setup>
import { IonList, IonItem, IonLabel, IonIcon, IonToggle, IonAvatar } from "@ionic/vue";
import home from "@/assets/parler/home.svg";
import { useGlobalStore } from "../stores/global";
import connectionsIcon from "@/assets/connections.svg";
import { computed, ref, onMounted } from "vue";
import moderationIcon from "@/assets/moderation_icon.svg";
import UserAvatar from "./UserAvatar.vue";
import { useAdminStore } from "@/stores/admin";
import { useRouter, useRoute } from "vue-router";
import share from "@/assets/parler/share.svg";
import {
  mail,
  cog,
  moonSharp,
  sunnySharp,
  logOutSharp,
  refresh,
  compass,
  bag,
  helpCircleOutline,
} from "ionicons/icons";
import parlerPay from "@/assets/parler/parler_pay.svg";
import { useAuthStore } from "@/stores/auth.js";

const props = defineProps({
  avatar: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["open", "openInvite", "close"]);
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const router = useRouter();
const route = useRoute();
const username = authStore.currentUser.username;

const version = import.meta.env.VITE_APP_VERSION;

const date = import.meta.env.VITE_BUILD_DATE;

const hasViolations = computed(() => {
  return globalStore.userViolations.length > 0;
});

const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("employee")
  );
});

const isCurrentUserAdmin = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("administrator")
  );
});

onMounted(() => {
  if (!hasViolations.value) {
    globalStore.fetchUserViolations();
  }
});

const navigateToViolations = () => {
  if (urlIsViolations.value) {
    emit("close");
    return;
  }
  router.push("/violations");
};

let activeRoute;
if (route.path === `/${username}`) {
  activeRoute = "profile";
} else if (route.path === `/${username}/connections`) {
  activeRoute = "connections";
} else if (route.path === "/settings") {
  activeRoute = "settings";
} else {
  activeRoute = "home";
}

const forceRefresh = () => {
  // Clear cache
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.controller.postMessage({ action: "clearCache" });
  }

  // Reload the page
  window.location.reload(true);
};

const navigateToProfile = () => {
  if (urlIsProfile.value) {
    emit("close");
    return;
  }
  const encodedUsername = encodeURIComponent(username);

  router.push(`/${encodedUsername}`);
};

const navigateToHome = () => {
  if (urlIsHome.value) {
    emit("close");
    return;
  }
  router.push(`/feed`);
};

const navigateToConnections = () => {
  if (urlIsConnections.value) {
    emit("close");
    return;
  }
  const encodedUsername = encodeURIComponent(username);

  router.push(`/${encodedUsername}/connections`);
};

const navigateToSettings = () => {
  if (urlIsSettings.value) {
    emit("close");
    return;
  }
  router.push(`/settings`);
};

const openKnowledgeCenter = () => {
  window.open("https://help.parler.com/en/hc/90853520", "_blank");
};

const openParlerStore = () => {
  window.open("https://shop.parler.com", "_blank");
};

const openParlerPay = () => {
  window.open("https://parlerpay.com/login", "_blank");
};

const urlIsProfile = computed(() => {
  return router.currentRoute.value.path === `/${username}`;
});

const urlIsConnections = computed(() => {
  return router.currentRoute.value.path === `/${username}/connections`;
});

const urlIsSettings = computed(() => {
  return router.currentRoute.value.path === `/settings`;
});

const urlIsHome = computed(() => {
  return router.currentRoute.value.path === `/feed`;
});

const urlIsViolations = computed(() => {
  return router.currentRoute.value.path === `/violations`;
});

const dark_mode = computed({
  get: () => globalStore.settings.dark_mode,
  set: (value) => {
    globalStore.updateSettings("dark_mode", value);
  },
});

function loginAdmin() {
  useAdminStore().loginAdmin();
}

function logout() {
  authStore.setUnauthenticated(router);
  router.push("/login");
}
</script>
<style scoped>
ion-icon .active {
  color: var(--tertiary-color);
}

.primary {
  color: var(--primary-color);
}

.avatar {
  padding: 2px;
  /* Border width */
  border-radius: 50%;
}

.avatar.primary {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.avatar img {
  display: block;
  border-radius: 50%;
}
</style>
